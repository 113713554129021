import React from "react";
import styled from "styled-components";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
const ImageCard = ({ img, name, designation, alt }) => {
  return (
    <Card className="custom-card ">
      <CardContent className="h-100 p-0 row flex-column">
        <img src={img} alt={alt} />
        <Box className="my-auto">
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {designation}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
const MentorImageCard = ({ img, name, designation, place, alt }) => {
  return (
    <Card className="custom-card-mentor">
      <CardContent className="h-100 p-0 row flex-column">
        <img src={img} alt={alt} />
        <Box className="my-auto">
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {designation}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {place}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
const LongImageCard = () => {
  return (
    <div className="long-custom-card col-md-6 col-sm-6 ">
      <img src="/landingPage/team/team.jpeg" alt="team" />
    </div>
  );
};
const OurTeam = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Wrapper>
      <Typography
        sx={{
          fontSize: {
            md: 36,
            xs: 24,
          },
          fontWeight: 500,
        }}
        className="text-center"
      >
        Our <span className="bold">Team Members</span>
      </Typography>
      <Typography className="desc text-center">
        Leadership Team - In Existence Since 2021
      </Typography>
      <Grid container spacing={1}>
        {/* <Grid item xs={12} md={4} lg={3}>
          <ImageCard
            name="Ankita Jaiswal"
            designation="CRO"
            img="/landingPage/team/ankita-mar.jpeg"
            alt="CRO"
          />
        </Grid> */}
        {/* <Grid item xs={12} md={4} lg={3}>
          <ImageCard
            name="Prakash Kumar"
            designation="CPE"
            img="/landingPage/team/prakash-dev.png"
            alt="CPE"
          />
        </Grid> */}

        <Grid item xs={12} md={4}>
          <ImageCard
            name="Alok Rawat"
            designation="Asst. Manager"
            img="/landingPage/team/alok-dev.png"
            alt="Manager"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageCard
            name="Ankita Chaudhary"
            designation="Asst. Manager"
            img="/landingPage/team/ankita-dev.jpeg"
            alt="Manager"
          />
        </Grid>
        {/* <Grid item xs={12} md={4} lg={3}>
          <ImageCard
            name="Aditi Jain"
            designation="Asst. Manager"
            img="/landingPage/team/aditi-dev.png"
            alt="Developer"
          />
        </Grid> */}
        <Grid item xs={12} md={4}>
          <ImageCard
            name="Akanksha Verma"
            designation="Customer Support Head"
            img="/landingPage/team/akanksha-cus.jpeg"
            alt="Support"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <LongImageCard />
        </Grid>

        <Grid item xs={12} md={4}>
          <ImageCard
            name="Rohit Patel"
            designation="Sale Executive"
            img="/landingPage/team/rohit-mar.png"
            alt="Sales"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <ImageCard
            name="Himanshu Kumar"
            designation="Sales Co-Ordinator"
            img="/landingPage/team/himanshu-dev.png"
            alt="Developer"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageCard
            name="Priyanshu Man"
            designation="Sales Co-Ordinator"
            img="/landingPage/team/priyanshu-dev.png"
            alt="Developer"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <ImageCard
            name="Nitin Verma"
            designation="UI/UX Designer"
            img="/landingPage/team/nitin-ui.png"
            alt="UI/UX Designer"
          />
        </Grid>

        {/* <Grid item xs={12} md={4} lg={3}>
          <ImageCard
            name="Babli"
            designation="Customer Support"
            img="/landingPage/team/babli-cus.png"
            alt="Support"
          />
        </Grid> */}
      </Grid>
      <Typography
        sx={{
          fontSize: {
            md: 36,
            xs: 24,
          },
          fontWeight: 500,
          mt: 3,
        }}
        className="text-center"
      >
        Our <span className="bold">Mentors</span>
      </Typography>
      <Carousel
        className="col-10 mb-5 py-5 mx-auto"
        responsive={responsive}
        autoPlay={true}
        infinite={false}
        autoPlaySpeed={3000}
      >
        <MentorImageCard
          name="Dr. Sandeep Tiwari"
          designation="Director"
          place="Darbhanga College of Engineering"
          img="/landingPage/team/sandeepsir-prof.png"
          alt="Professor"
        />
        <MentorImageCard
          name="Dr. Sudhakar Tripathi"
          designation="Associate Professor"
          place="REC, Ambedkar Nagar"
          img="/landingPage/team/sudhakarsir-prof.png"
          alt="Professor"
        />
        <MentorImageCard
          name="Mr. Avaneesh Kumar Yadav"
          designation="Asst. Professor"
          place="REC, Ambedkar Nagar"
          img="/landingPage/team/avnishsir-prof.png"
          alt="Professor"
        />
      </Carousel>
    </Wrapper>
  );
};

export default OurTeam;
const Wrapper = styled.div`
  .custom-card {
    margin: 1%;
    border-radius: 1.25rem;
    border: 1px solid rgba(13, 33, 60, 0.2);
    box-shadow: none;
    height: 350px;
  }
  .custom-card-mentor {
    margin: 1%;
    padding: 1%;
    border-radius: 1.25rem;
    border: 1px solid rgba(13, 33, 60, 0.2);
    box-shadow: none;
    height: 400px;
    img {
      height: 75%;
      width: 100%;
      object-fit: cover;
      border-radius: 1.25rem 1.25rem 0 0;
    }
  }
  .long-custom-card {
    margin: 1%;
    border-radius: 1.25rem;
    border: 1px solid rgba(13, 33, 60, 0.2);
    box-shadow: none;
    height: 350px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 1.25rem;
    }
  }
  p {
    text-align: center;
    color: #31436d;
  }
  .bold {
    font-weight: 700;
  }
  .desc {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
  }
  img {
    height: 75%;
    width: 100%;
    object-fit: cover;
  }
`;
