import { React, useState } from "react";
import { Typography } from "@mui/material";

import { useQuery } from "react-query";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import TestimonialCard from "./TestimonialCard";
import { getAllTestimonials } from "../../../services/school.service";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useQuery({
    queryKey: ["getAllTestimonials"],
    queryFn: () => getAllTestimonials(),
    onSuccess: ({ data }) => {
      const testimonialsResponse = data?.list;
      setTestimonials(testimonialsResponse);
    },
  });

  const isDesktop = window.innerWidth >= 968;
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Wrapper className="py-5">
      <Typography className="text-center pt-4">
        <span className="section-heading py-2 px-4">Our Client Say</span>
      </Typography>
      <Typography className="heading mt-5 cold">
        Client love us & we love them
      </Typography>
      <Typography className="desc text-center mt-4">
        Trusted by over 4,000 clients worldwide
      </Typography>

      <Carousel
        className="mb-5 py-5 mx-auto"
        autoPlay={true}
        infinite={true}
        autoPlaySpeed={5000}
        arrows={false}
        centerMode={isDesktop}
        responsive={responsive}
      >
        {testimonials.map((item, index) => {
          return <TestimonialCard key={index} testimonial={item} />;
        })}
      </Carousel>
    </Wrapper>
  );
};

export default Testimonials;

const textBluePrimary = "#31436D";
export const Wrapper = styled.div`
  background-color: #eef6ff;
  .heading {
    color: ${textBluePrimary};
    text-align: center;
    font-size: 36px;
    font-weight: 700;
  }
  .bold {
    font-weight: 800;
  }
  .desc {
    color: ${textBluePrimary};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 27px;
  }

  .section-heading {
    border-radius: 500px;
    border: 1px solid rgba(144, 156, 255, 0.3);
    background: #f5f6fe;
    color: #3347ed;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .imageCard {
    background-color: white;
    aspect-ratio: 16/9;
  }
  .react-multi-carousel-item {
    opacity: 0.6;
  }
  .react-multi-carousel-item--active {
    opacity: 1;
  }

  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
      width: 100%;
    }
    .desc,
    .desc2 {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }
`;
