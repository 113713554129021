import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
const TestimonialCard = ({ testimonial }) => {
  return (
    <Wrapper className="col-11 m-auto">
      <div className="testimonialHeader row p-4 px-5">
        <div
          className="img"
          style={{
            backgroundImage: "url(" + testimonial.personProfilePic + ")",
          }}
        ></div>
        <div className="col">
          <Typography
            sx={{
              color: "#FFF",
              fontSize: {
                xs: 14,
                md: 20,
              },
              fontWeight: 700,
            }}
          >
            {testimonial.createdBy}
          </Typography>

          <Typography
            sx={{
              color: "#FFF",
              fontSize: {
                xs: 12,
                md: 14,
              },
              fontWeight: 500,
            }}
          >
            {testimonial.personRole}
          </Typography>
        </div>
      </div>
      <div className="testimonialContent row py-md-5 py-4 px-4">
        <div
          className="img2"
          style={{
            backgroundImage: "url(" + testimonial.schoolLogo + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderRadius: "50%",
          }}
        ></div>
        <Typography
          className="col ps-md-5 ps-2"
          sx={{
            color: "#31436D",
            fontSize: {
              xs: 10,
              md: 14,
              lg: 18,
            },
            fontWeight: 500,
            lineHeight: {
              xs: "1.5rem",
              lg: "2.75rem",
            },
          }}
        >
          {testimonial.testimonial}
        </Typography>
      </div>
    </Wrapper>
  );
};

export default TestimonialCard;
const Wrapper = styled.div`
  .testimonialHeader {
    border-radius: 1.25rem 1.25rem 0px 0px;
    background: #2789fd;
    .img {
      width: 5rem;
      height: 5rem;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
    }
  }
  .testimonialContent {
    border-radius: 0 0 1.25rem 1.25rem;
    border: 0.8px solid rgba(13, 33, 60, 0.1);
    background: #fff;
    box-shadow: 0px 5px 1.75rem 0px rgba(19, 70, 137, 0.05);
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 5;
      line-clamp: 5;
    }
    height: 20rem;
    .img2 {
      width: 10rem;
      height: 10rem;
    }
  }

  @media (max-width: 768px) {
    .testimonialContent {
      height: auto;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 5;
        line-clamp: 5;
      }
      .img2 {
        width: 8rem;
        height: 8rem;
      }
    }
  }
`;
