import React, { useEffect } from "react";
import { Box, Container, Stack, Typography, styled } from "@mui/material";
const TermAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Container>
      <ul>
        <Typography variant="h4" mt={3} fontWeight={600}>
          Terms of Use
        </Typography>
        <Typography variant="h6" my={2} fontWeight={600}>
          Please Read the Following "Terms of Use" Carefully.
        </Typography>
        <Typography variant="h6" my={2} fontWeight={600}>
          IF YOU ARE UNDER THE AGE OF 18, YOU MUST HAVE YOUR PARENT OR GUARDIAN
          READ THE AGREEMENT BELOW AND AGREES TO IT FOR YOU. IF YOU DON’T GET
          YOUR PARENT OR GUARDIAN TO READ AND AGREE TO THIS, YOU DON’T HAVE
          PERMISSION TO USE THIS SITE. PLEASE READ THESE TERMS OF USE
          ("AGREEMENT") CAREFULLY BEFORE USING THE SERVICES OFFERED BY EKALSUTRA
          EDTECH PRIVATE LIMITED BY ACCESSING, VIEWING, VISITING, USING, OR
          INTERACTING ON THIS PLATFORM OF Ekalsutra OR WITH ANY BANNER, POP-UP,
          OR ADVERTISING THAT APPEARS ON IT, YOU ACKNOWLEDGE THAT YOU HAVE READ
          THIS AGREEMENT AND THAT YOU ACCEPT AND WILL BE BOUND BY THIS
          AGREEMENT. IF YOU DO NOT UNCONDITIONALLY AGREE TO ALL THE TERMS AND
          CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE THE SERVICES.
        </Typography>
        <Title mt={2}>Definitions</Title>
        <Text>
          "Ekalsutra" "we" "us" or "our" shall mean, Ekalsutra Edtech Private
          Limited, a company registered under Companies Act 1956, having its
          registered address at Near Gomti River, KNI Kasba Sultanpur, Uttar
          Pradesh, India. "Users", "You", "Your" shall mean and include the
          schools, its teachers and parents, who accesses the Ekalsutra Platform
          for availing the Services. <br /> "Platform", "Ekalsutra Platform"
          means and includes Ekalsutra Website and Ekalsutra Mobile App
          developed and owned by Ekalsutra, to enable the Users to avail the
          Services through the Platform. "Services" shall mean Services offered
          through the Platform, to the Users, which shall include
        </Text>
        <li>
          <Text mt={1}>
            Enabling the school teachers to send instant messages to parents.
          </Text>
          <Text mt={1}>
            To create various groups as per preference and sending specific
            messages to the members of the groups.
          </Text>
          <Text mt={1}>To share achievements of students.</Text>
          <Text mt={1}>
            To keep parents updated about the daily activities and projects
            conducted at schools. "School" shall mean and include all the
            schools registered on the Platform.
          </Text>
          <Text mt={1}>
            "Online Stores" means Android and iOS stores or any other online
            store or portal where the App would be available for download
          </Text>
          <Title mt={2}>Parties to this agreement</Title>
          <Text>
            Visitors, viewers, users, subscribers, members, affiliates, or
            customers, collectively referred to herein as "Users," are parties
            to this agreement. If User is accessing and/or using the Platform on
            behalf of User’s employer or as a consultant or agent of a third
            party (collectively your company, school), User represent and
            warrant that User has the authority to act on behalf of and bind his
            company, School to the terms of use and everywhere in this Terms of
            use that refers to User, shall also include User’s company, school.
          </Text>
          <Text mt={1}>
            Ekalsutra together with its subsidiaries are the creator of this
            Platform and accordingly its owners and/or operators are parties to
            this Agreement.
          </Text>
          <Title mt={2}>Modification of terms of use</Title>
          <Text>
            Ekalsutra wants to continually improve the Platform experience for
            the Users, so Ekalsutra’s terms may change from time to time.
            Ekalsutra reserves the right to modify any provision hereof from
            time to time, and such modification shall be effective immediately
            upon its posting on the Platform. User agrees to be bound to any
            changes to this "terms of use" if User s continues to use this
            Platform after any such modification is posted. Users of the
            Platform have an affirmative duty, to keep themselves informed of
            changes.
          </Text>
          <Title mt={2}>Ekalsutra Privacy Policy</Title>
          <Text>
            By using our Services User provide us with information, files, and
            folders that User submits to Platform (together, "your files"). User
            retains full ownership of the files. Ekalsutra do not claim any
            ownership on any of it. These Terms do not grant us any rights to
            User’s files or intellectual property except for the limited rights
            that are needed to render the Services, as explained herein.
            <br /> We may need User’s permission to do things the User asks us
            to do with User’s files, for example, hosting files, sharing them at
            User’s discretion or sending reminders. User gives us the
            permissions we need to do those things solely to provide the
            Services. This permission also extends to trust third parties we
            work with to provide the Services.
            <br />
            User shall be solely responsible for the User’s conduct, the content
            of User files and folders, and User’s communications with others
            while using the Services. The Personal Information of the User or
            "your files" shall be subject to the Privacy Policy applicable to
            the Platform.
            <br />
            The privacy policy explains how Ekalsutra treats your
            personal/confidential information, and protects your privacy when
            you visit and/or use the Platform. You agree to the use of Your data
            in accordance with these Terms of use and Ekalsutra’s privacy
            policy.
          </Text>
          <Title mt={2}>Scope of Services</Title>
          <Text>
            The Platform is a easy to use Platform which helps to facilitate
            communication between the schools, their teachers and students.
            Schools may use the Platform to send out instant messages to
            parents. The Schools may create various groups as per their
            preferences and specific messages could be sent out to each or all
            of the groups. The Platform helps parents to receive the messages of
            any updates notifications, activities, or achievements of their
            children instantly on their smartphones.
            <br />
            The Platform provides features that allow User to share the files
            with others using the Platform. There are many things that Users may
            do with the files (for example, copy it, modify it, re-share it).
            Please consider carefully what you choose to share or make public,
            Ekalsutra has no responsibility for any such activity. Ekalsutra
            shall not assume and shall not be liable for any and all claims
            which may arise do to sharing of such files. You shall be solely
            responsible for sharing your files.
          </Text>
          <Title mt={2}>Registration</Title>
          <Text>
            As a condition to using Services, you must register on the Platform
            by choosing the role of the User either as the School, teacher,
            parent or student and thereafter select a password and user name
            ("User ID"). You will provide Ekalsutra with accurate, complete, and
            updated registration information. Failure to do so will constitute a
            breach of this Agreement, which may result in immediate termination
            of your account. You may not (i) select or use as a User ID a name
            of another person with the intent to impersonate that person; or
            (ii) use as a User ID a name subject to any rights of a person other
            than you without appropriate authorization. Ekalsutra reserves the
            right to refuse registration of or cancel a User ID in its
            discretion. You will be responsible for maintaining the
            confidentiality of your password.
          </Text>
          <Title mt={2}>User content</Title>
          <Text>
            User represent, warrant, and agree that no content/details shared by
            the User on the Platform ("User Content"), violates or infringes
            upon the rights of any third party, including copyright, trademark,
            privacy, publicity, or other personal or proprietary rights,
            breaches or conflicts with any obligation, such as a confidentiality
            obligation, or contains libellous, defamatory, or otherwise unlawful
            material.
            <br />
            User grants Ekalsutra a personal, limited, revocable,
            non-transferable and nonexclusive right to use the User Content, for
            the providing the Services. User also grants Ekalsutra the right (i)
            to display User Content on the Platform (ii) promote or distribute
            it for the access of other Users.
            <br />
            User agrees that any content provided as a result of any
            communication between User and Ekalsutra, shall be deemed to be a
            submission. All content of the submission, shall become the
            exclusive property of Ekalsutra and may be used, without further
            permission,
            <br />
            Users further agree not to use the Platform, or any part thereof,
          </Text>

          <li>
            <Text mt={1}>
              To "stalk" or harm any other Users or any other person in any way;
            </Text>
          </li>
          <li>
            <Text mt={1}>
              Impersonate any person or entity, including, but not limited to,
              Ekalsutra officer, director, or representative, or falsely state
              or otherwise misrepresent User’s affiliation with a person or
              entity;
            </Text>
          </li>
          <li>
            <Text mt={1}>
              Falsify headers or otherwise manipulate identifiers in order to
              disguise the origin of any User Content transmitted on or through
              the Web Site;
            </Text>
          </li>
          <li>
            <Text mt={1}>Disrupt the normal flow of dialogue in a forum;</Text>
          </li>
          <li>
            <Text mt={1}>
              Interfere with or disrupt the Platform or servers or networks
              connected to the Platform, or disobey any requirements,
              procedures, policies or regulations of networks connected to the
              Platform;
            </Text>
          </li>
          <li>
            <Text mt={1}>
              "Hack" infiltrate or otherwise seek to gain unauthorized access
              to, or compromise the integrity of, the Platform;
            </Text>
          </li>
          <li>
            <Text mt={1}>
              Harvest, collect or store information about the other Users or the
              User Content posted by others on the Platform or use such
              information for any purpose inconsistent with the purpose of this
              Platform;
            </Text>
          </li>
          <li>
            <Text mt={1}>
              Use any automated procedure to gather information or data on or
              from the Platform by means of what is commonly called a "bot" or
              otherwise;
            </Text>
          </li>
          <li>
            <Text mt={1}>
              User will not violate any local, state, federal, national, rules
              or regulations.
            </Text>
          </li>
          <li>
            <Text mt={1}>
              Breach or attempt to breach the security of software, network,
              servers, data, computers or other hardware relating to the
              Platform or that of any third party that is hosting or interfacing
              with any part of the Platform or use or distribute on the Platform
              software or other tools or devices designed to compromise privacy
              or security; or
            </Text>
          </li>
          <li>
            <Text mt={1}>
              Create any frames at any other Platform pertaining to or using any
              of the information provided through the Platform, or promotes
              bonus items, giveaways, random drawings, contests or prizes on the
              Platform.
            </Text>
          </li>
        </li>
        <Title mt={2}>Fees and payment; Responsibility for usage charges</Title>
        <Text>
          Access to and use of the Services and the features therein are free,
          however in future, Ekalsutra may revamp and enhance the Services or
          features and thereby may provide new or additional special features
          which Ekalsutra may charge for. Therefore, in future Ekalsutra
          reserves the right to require payment of fees from requisite Users for
          certain additional Services, User will pay all applicable fees for
          those Services, as will be described on the Services as notice. If
          User continues to use those Services following such notification, that
          use constitutes User’s acceptance of any new or increased charges.
          <br />
          It is hereby clarified that the Users of Platform may be required to
          commercially transact through the Platform for various purposes like
          for instance- payment of school fees/holiday fees by the parents to
          such respective schools, colleges, educational institute, universities
          etc. Such commercial transactions shall be conducted by the Users
          based on the commercial understanding they have with the respective
          schools, colleges, educational institutes, universities etc. and vice
          versa (collectively all ‘Payment Transactions’ between the Users of
          the Platform) without any liability to Ekalsutra. The Users of
          Platform hereby agree, acknowledge that they are aware that any and
          all ‘Payment Transactions’ which they enter as per the commercial
          understanding with the other Users of the Platform shall be directed
          to the system of the payment getaway provider and such systems of
          payment getaway shall have complete control for facilitating such
          Payment Transactions in a safe and secure manner and Ekalsutra and its
          systems shall no have no control over such Payment Transactions.
          <br />
          All commercial/contractual terms for the Payment Transactions are
          offered by and agreed to between the Users alone. The
          commercial/contractual terms include without limitation price, fees,
          admission fees, administrative fees, payment methods, payment terms,
          date, period warranties etc. related to the services. Ekalsutra does
          not have any control or does not determine or advise or in any way
          involve itself in the offering or acceptance of such
          commercial/contractual terms pertaining to the Payment Transactions
          between the Users of Platform. Further, Ekalsutra shall not be liable
          to the Users or any third party for any indirect, special, incidental,
          consequential, punitive or exemplary damages or loss of any kind
          including but not limited to loss of profit, loss of revenues,
          business interruption, loss of business information, increased costs
          of operation or other commercial or economic loss, litigation costs
          and the like) howsoever caused, in connection with the ‘Payment
          transactions’ between the Users and Payment Gateway or between the
          Users through Payment Gateway.
          <br />
          It is hereby understood, accepted and agreed by the Users that the
          payment facility vide a payment gateway provided in the Platform is
          neither a banking nor financial service but is merely a facilitator
          providing an electronic, automated online electronic payment,
          receiving payment on delivery, collection and remittance facility for
          the transactions on the Platform using the existing authorized banking
          infrastructure and credit card payment gateway networks.
          <br />
          Further, by providing payment facility through Payment Gateway,
          Ekalsutra is neither acting as trustees nor acting in a fiduciary
          capacity with respect to the transaction or the transaction price. The
          Users of Platform hereby agree, acknowledge that upon transacting
          through Payment Gateway, they are bound under the terms and conditions
          of Payment Gateway without any recourse or liability to Ekalsutra.
          <br />
          Note that, by using the Services, User may receive e-mail or text
          messages on the phone or mobile device, which may cause the User to
          incur usage charges or other fees or costs in accordance with your
          wireless or data service plan. Any and all such charges, fees, or
          costs are User’s sole responsibility. User shall consult with his
          wireless carrier to determine what rates, charges, fees, or costs may
          apply to User’s use of the Services. Ekalsutra is not liable for such
          charges in any manner whatsoever.
        </Text>
        <Title mt={2}>Features of Platform</Title>
        <Text>
          The Platform allows school administrators, teachers and parents to
          stay connected at all times. The key features of this Platform are as
          under;
        </Text>
        <Box px={1}>
          <Title mt={1}>1. Manage Expectations</Title>
          <Text>
            The Platform provides notifications to the Parents regarding the
            activities, assignments conducted by the teachers for the
            development of students.
          </Text>
          <Title mt={1}>2. Sharing Achievements</Title>
          <Text>
            The Platform provides a feature where the various achievements of
            students at school can be shared with parents instantly through this
            feature.
          </Text>
          <Title mt={1}>3. Reminders</Title>
          <Text>
            The Platform provides a feature where the parents shall be updated
            with the daily home assignments or project submissions to be
            completed by the students. The teachers can send reminders for home
            assignment or project submissions any time by using this feature.
          </Text>
          <Title mt={1}>4. Creation of Groups</Title>
          <Text>
            The Platform provides a feature where the Users can create different
            groups as necessary, to provide notifications, updates, information,
            suggestions etc. depending upon the age group, and divisions of the
            students. Users understand and agree that, Users membership to a
            particular group, would be at the sole discretion of the group admin
            ("group admin"), who may accept or reject the Users request. The
            group admin can be either teachers, schools, educational institutes
            and other related entities ("Educational Institutes") or the parents
            and teachers ("End Users"). The group admin, reserves the right to
            remove any member of the group. In such case the Platform shall not
            be responsible for the decision of the group admin. The Users can
            also through this feature send or receive invite from the other
            Users to join the group. Group admin shall only invite those Users
            whom he wishes to subscribe. It shall be the group admin’s
            responsibility to maintain and monitor the accuracy of the list of
            Users for each of the respective groups.
            <br />
            If the User is an Educational Institute the Educational Institute
            must maintain the accuracy of the information relating to the
            Educational Institution. The Educational Institute shall only permit
            its teachers who are the current employees of the Educational
            Institutions to create the groups. Upon termination of employment of
            the teacher, the Educational Institute will require such teacher to
            delete and cease using the group. The Educational Institutes shall
            reserve the right to add, delete, invite any teacher to join the
            group. Ekalsutra shall not be responsible for any such decisions
            taken by the Educational Institutions.
            <br />
            If the Users are the End Users, the End Users agree and understand
            that, they shall receive notifications from only those groups, to
            which the End Users are a member. Once the End Users are a part of a
            particular group, the existing members of the group, shall be able
            to view personally identifiable information, as well as the,
            notifications, messages posted on the group by the End Users. The
            End Users can communicate amongst each other by creating such
            groups. The End Users agree and undertake not to post any message or
            notification which would hurt the sentiments or feelings of other
            End Users s. The End Users agree that they shall not post any wrong
            or misleading messages or notification on the groups. Users also
            agree and understand that, Ekalsutra do not have any control over
            the interactions, messages, or notifications that the User receives
            through the group, neither Ekalsutra verify the genuineness or
            reliability of any content shared through such groups.
          </Text>
        </Box>
        <Title mt={2}>Content Available</Title>
        <Text>
          User acknowledge that Ekalsutra does not make any representations or
          warranties about the material, data, and information, such as data
          files, text, facts and figures, audio files or other sounds,
          photographs, videos, or other images (collectively, the "Content")
          which User may have access to as part of the Services, or through
          User’s use of this Platform. Under no circumstances, Ekalsutra shall
          be held for liable any Content, including, but not limited to: any
          infringing Content, any errors or omissions in Content, or for any
          loss or damage of any kind incurred as a result of the use of any
          Content posted, transmitted, linked from, or otherwise accessible
          through or made available via the Platform. User understand that by
          using this Platform, User may be exposed to Content that is offensive,
          indecent, or objectionable. User agrees that User shall be solely
          responsible for reuse of Content made available through the Platform.
        </Text>
        <Title mt={2}>Licence and Rules of Conduct</Title>
        <Text>
          Subject to terms and conditions of this Agreement, Ekalsutra hereby
          grant User a non- exclusive, non-assignable, non-transferable and
          limited license to use the Platform and the Services, in accordance
          with this Agreement, only.
          <br />
          Users use of the Services is subject to all applicable local, state,
          national laws and regulations and the rules. Users agree not to
          reverse engineer, reproduce, publish, re-publish, dissemble,
          disseminate, modify, copy, distribute, transmit, display, perform,
          license, create derivative works from, transfer, or sell any material,
          service or feature, information, software of the Services and further
          agree not to use the Services as part of any another application,
          upload of any virus, use any content for commercial purposes, access
          or modify partially or otherwise to any source code,track or monitor
          the other users, do anything that puts an enormous/ unreasonable load
          on our servers, copy or create derivative work.
        </Text>
        <Title mt={2}>Social Media</Title>
        <Text>
          Ekalsutra may in the future, incorporate certain functions that allow
          you to use the Services through your accounts on certain supported
          third party services, such as social network platforms etc. ("Linked
          Accounts"). If you choose to use such features, you understand and
          agree that Ekalsutra may be required to access or use your Linked
          Account and You grant Ekalsutra the necessary rights as may be
          required. Your use of Linked Accounts shall always be subject to the
          rules and regulations or terms of services applicable to such social
          network platform.
        </Text>
        <Title mt={2}>Indemnity</Title>
        <Text>
          User agrees to hold harmless and indemnify Ekalsutra, and its
          subsidiaries, affiliates, officers, agents, and employees, advertisers
          or partners, without limitation, from and against any claims arising
          from or in any way related to the use of Ekalsutra by the Users,
          uploading User information, violation of any third Party rights
          pertaining to any intellectual property rights or confidential
          information, violation of these Terms of Use or any other actions
          connected with use of Ekalsutra Services, including any liability or
          expense arising from all claims, losses, damages (actual and
          consequential), suits, judgments, litigation costs and attorneys'
          fees, of every kind and nature. In such a case, Ekalsutra will provide
          the User with written notice of such claim, suit or action. The Users
          waive all rights to claim damages that may have caused to them by
          visiting and/or using this Platform...
        </Text>
        <Text fontWeight={600}>
          If the User uses Ekalsutra Learn then in addition to the terms and
          conditions that are applicable for Ekalsutra Users, following
          additional terms and conditions shall also be applicable to such Users
          of Ekalsutra:
        </Text>
        <Title mt={2}>Definition:</Title>
        <Text>
          Teacher User: Any person working or performing the role of teacher in
          any school or otherwise, and registered under Ekalsutra as User.
          <br />
          Reviewer: Any person registered under Ekalsutra as User and performs
          the role of reviewing the Question Content submitted by Teacher User
          <br />
          Selected Question: Question Content reviewed and approved by Reviewer
          <br />
          Question Points: For each Selected Questions, Teacher User associated
          with Selected Questions, are awarded points under Ekalsutra, such
          points <br />
          awarded to Teacher user are called as Question points Defunct
          Questions: Any question submitted by Teacher User and rejected by
          reviewers.
          <br />
          Review Points: The Reviewers associated with each Selected Question
          are awarded Review points.
          <br />
          User Content-shall mean to include content provided by User.
          <br />
          “Referral Code” shall mean a unique code assigned to the Referrer;
          <br />
          “Referrer" shall mean the User whose Referral Code has been used to
          buy Ekalsutra membership;
          <br />
          "Referral Recipient" or ‘Referee’ , shall mean and new User who avails
          the Services using a valid Referral Code;
          <br />
          “Referral Reward” shall mean any incentive in the form of extra one
          month free subscription to the Services provided to the Referrer or
          the Referral Recipient.
        </Text>
        <Title mt={2}>Teacher User:</Title>
        <Text>
          Teacher User shall submit Question Content into Ekalsutra. Teacher
          User while submitting the Question Content shall ensure that Question
          Content are in accordance to the acceptable standards of education and
          does not violate any representations as provided under such Ekalsutra
          terms and conditions for submission of Content into Ekalsutra. The
          Question Content so submitted by Teacher Users shall be validated and
          reviewed by Ekalsutra Reviewers registered with Ekalsutra. If the
          Ekalsutra Reviewers select the questions from the Question Content
          submitted by Teacher User then such selected questions shall be marked
          as selected questions within Ekalsutra. For each Selected Question,
          Teacher User associated with Selected Question, are awarded points
          under Ekalsutra as Question Points. If the Question points accumulated
          by Teacher User reaches a threshold limit as specified under Ekalsutra
          score card for Teacher User then the Teacher User shall be entitled to
          apply for encashment of accumulated Question Points, basis the terms
          mentioned under Teacher User score card, accordingly upon exercise of
          encashment of Question points by Teacher User, the Teacher User shall
          be paid by Ekalsutra at the then applicable rates for conversion of
          Question points, which would be then depicted under Teacher User score
          card. If the Question Content submitted by Teacher User for validation
          to the Reviewers are found to be Defunct Questions on a repetitive
          basis then Ekalsutra has the discretion to deregister such Teacher
          User without incurring any liability whatsoever. Teacher user further
          acknowledges that Ekalsutra has the final discretion in deciding the
          accumulated Question points against each Teacher and its equivalent
          conversion rate for paying to Teacher User. Teacher User further
          declare that they are exempted from the preview of GST.
        </Text>
        <Title mt={2}>Reviewer:</Title>
        <Text>
          The Reviewers associated with each Selected Question are awarded
          Review points. If the Review points accumulated by Reviewers reaches a
          threshold limit as specified under Ekalsutra score card for Reviewers
          then the Reviewers shall be entitled to apply for encashment of
          accumulated Review Points, basis the terms mentioned under Reviewers
          score card, accordingly upon exercise of encashment of Review points
          by Reviewers, the Reviewers shall be paid by Ekalsutra at the then
          applicable rates for conversion of Review points, which would be then
          depicted under Reviewers score card.
          <br />
          The Reviewers are primarily responsible for review of Question Content
          submitted by Teacher User. Each Reviewer represents to perform the
          review of Question Content in a diligent manner by taking all
          reasonable skill and care for review of Question Content. The
          Reviewers acknowledge that the Question Content reviewed by the
          Reviewers shall undergo quality analysis check by Ekalsutra, and
          during such quality check process for the reviewed question and
          Selected Questions, if Ekalsutra finds that the Selected Question are
          not of satisfactory quality as per standards of education then in such
          circumstances, the Ekalsutra reserves the right to deregister such
          Reviewers without incurring any lability whatsoever. Reviewers further
          acknowledges that Ekalsutra has the final discretion in deciding the
          accumulated Review points against each Reviewer and its equivalent
          conversion rate for paying to Reviewer.
        </Text>
        <Title mt={2}>Subscription Fees:</Title>
        <Text>
          Parents can avail subscription of Ekalsutra, upon payment of requisite
          subscription fees as mentioned on Ekalsutra. Such subscription fees
          shall be paid in advance before the start of such subscription period.
          Each eligible subscription period of Ekalsutra for auto-renewal shall
          be auto renewed for the same subscription period unless the parent
          turn-off the auto-renew feature at least 24 hours before the end of
          such eligible current subscription period. The subscription shall be
          managed by the User and auto-renewal switch can be accessed on User’s
          payment history page on Ekalsutra. Upon turning-off the auto-renewal
          switch, the User shall have the access of Ekalsutra for the remaining
          period of then current subscription period.
          <br />
          Payment by credit card. If as User you are paying by credit card or
          electronic funds transfer, you authorize Ekalsutra to charge your
          credit card or bank account for all fees payable at the beginning of
          the Subscription Term and any Renewal Term and any other amounts owing
          under this Agreement, from time to time. You as User further authorize
          Ekalsutra to use a third party to process payments, and consent to the
          disclosure of your payment information to such third party.
          <br />
          Payment Information. All payment obligations are non-cancelable and
          all amounts paid are non-refundable, except as specifically provided
          for in this Agreement. In the event that payment is not made in
          accordance with this Agreement, you will remain liable to make payment
          but we reserve the right to terminate or restrict the Subscription
          Service until payment is made.
          <br />
          Sales Tax. All fees are exclusive of any applicable sales taxes, VAT,
          which Ekalsutra will charge as applicable. You agree to pay any taxes
          applicable to your use of the Subscription Service, including all
          sales, use, value-added, transfer, and telecommunications taxes.
          <br />
          Late Payments. User shall pay interest on all late payments at the
          lesser of (i) 1.5% per month or (ii) the highest rate permissible
          under applicable law, calculated daily and compounded monthly. User
          shall reimburse Ekalsutra for all costs incurred in collecting any
          late payments, including, without limitation, attorneys’ fees.
        </Text>
        <Title mt={2}>Intellectual property:</Title>
        <Text>
          User represent and warrant that all User Content that User post to the
          Ekalsutra Subscription Service shall be User’s wholly original
          material (except for material that User are using with the permission
          of its owner), and does not infringe any copyright, trademark or other
          rights of any third party including without limitation any rights of
          privacy or publicity.
          <br />
          This is an Agreement for access to and use of the Subscription
          Service, solely during the Term, and Users are not granted a license
          to any software by this Agreement. The Subscription Service is
          protected by intellectual property laws. By using the Ekalsutra
          Platform, Users acknowledge and agree that Ekalsutra is the owner of
          all Intellectual Property Rights relating to the Ekalsutra Platform
          and any other materials provided or made available to User under this
          Agreement. Nothing in this Agreement shall transfer any ownership or
          proprietary interest in Ekalsutra’ Intellectual Property Rights to
          Users.
          <br />
          Ekalsutra retain all ownership rights in the Ekalsutra Software. Users
          agree not to copy, rent, lease, sell, distribute, alter, or create
          derivative works based on the Ekalsutra Platform or the Subscription
          Service in whole or in part, by any means, except as expressly
          authorized in writing by Ekalsutra. Ekalsutra and other marks that
          Ekalsutra use from time to time are Ekalsutra trademarks and Users
          shall not use them without Ekalsutra’s prior written permission.
        </Text>
        <Title mt={2}>Legal guardian or parents of Minors:</Title>
        <Text>
          Persons who are “competent/capable” of contracting within the meaning
          of the Indian Contract Act, 1872 shall be eligible to use Ekalsutra.
          As a minor if you wish to use our products or services, such use shall
          be made available to minors by their legal guardian or parents.
          Ekalsutra will not be responsible for any consequence that arises as a
          result of misuse of any kind of Ekalsutra’s products or services that
          may occur by virtue of any person including a minor using the services
          provided. By using the products or services as User you warrant that
          all the data provided by you is accurate and complete and that as User
          you have obtained the consent of parent/legal guardian(in case of
          minors). Ekalsutra reserves the right to terminate your subscription
          and / or refuse to provide you with access to the products or services
          if it is discovered that you are under the age of 18 years and the
          consent to use the products or services is not made by your
          parent/legal guardian or any information provided by you is
          inaccurate.
        </Text>
        <Title mt={2}>Ekalsutra User indemnification:</Title>
        <Text>
          You hereby indemnify, defend, and hold Ekalsutra, Ekalsutra's
          distributors, agents, representatives and other authorized users, and
          each of the foregoing entities" respective resellers, distributors,
          service providers and suppliers, and all of the foregoing entities'
          respective officers, directors, owners, employees, agents,
          representatives, harmless from and against any and all losses,
          damages, liabilities and costs arising from your use of our product
          and service <br />
          In the event of breach of the terms and conditions of this Terms of
          Use by the User, the User shall be promptly liable to indemnify
          Ekalsutra for all the costs, losses and damages caused to Ekalsutra as
          a result of such a breach. <br />
          In the event of your breach of this Terms of Use, you agree that
          Ekalsutra will be irreparably harmed and will not have an adequate
          remedy in money or damages. Ekalsutra therefore, shall be entitled in
          such event to obtain an injunction against such a breach from any
          court of competent jurisdiction immediately upon request. Ekalsutra’s
          right to obtain such relief shall not limit its right to obtain other
          remedies
        </Text>
        <Title mt={2}>Disclaimer</Title>
        <Text>
          PERTAINING TO THE CONTENTS OF THIS PLATFORM, Ekalsutra MAKES NO
          REPRESENTATION OR WARRANTY, EITHER EXPRESS OR IMPLIED, AS TO ITS
          ACCURACY, ADEQUACY, SUFFICIENCY, OR FREEDOM FROM DEFECT OF ANY KIND.
          USERS ASSUME ALL THE RISK OF VIEWING, READING, USING, OR RELYING UPON
          THIS INFORMATION. UNLESS THE USER HAVE OTHERWISE FORMED AN EXPRESS
          CONTRACT TO THE CONTRARY WITH Ekalsutra, USER HAS NO RIGHT TO RELY ON
          ANY INFORMATION CONTAINED HEREIN AS ACCURATE. Ekalsutra MAKES NO SUCH
          WARRANTY. USER DOWNLOADS INFORMATION FROM THIS SITE AT THEIR OWN RISK.
          Ekalsutra MAKES NO WARRANTY THAT DOWNLOADS ARE FREE OF CORRUPTING
          COMPUTER CODES, INCLUDING, BUT NOT LIMITED TO, VIRUSES AND WORMS. ANY
          MATERIAL UPLOADED OR OTHERWISE DEMONSTRATED THROUGH THE PLATFORM IS
          DONE AT USER’S OWN DISCRETION AND RISK AND THAT USER WILL BE SOLELY
          RESPONSIBLE FOR ANY CLAIMS ARISING OUT OF UPLOADING ANY SUCH MATERIAL.
          Ekalsutra HAS NO SPECIAL RELATIONSHIP WITH OR FIDUCIARY DUTY TO THE
          USER. USER ACKNOWLEDGES THAT Ekalsutra HAS NO CONTROL OVER, AND NO
          DUTY TO TAKE ANY ACTION REGARDING WHICH USERS GAINS ACCESS TO THE
          SERVICES; WHAT CONTENT USER ACCESSES VIA THE SERVICES; WHAT EFFECTS
          THE CONTENT MAY HAVE ON THE USER; HOW USER MAY INTERPRET OR USE THE
          CONTENT; OR WHAT ACTIONS USER MAY TAKE AS A RESULT OF HAVING BEEN
          EXPOSED TO THE CONTENT. USER RELEASE Ekalsutra FROM ALL LIABILITY FOR
          USER HAVING ACQUIRED OR NOT ACQUIRED CONTENT THROUGH THE SERVICES.
          Ekalsutra RESERVES THE RIGHT, AND SHALL HAVE NO OBLIGATION, TO
          INSPECT, INVESTIGATE, OR EXAMINE, USER’S USE OF THE SERVICES IN ORDER
          TO DETERMINE WHETHER A VIOLATION OF AGREEMENT HAS OCCURRED OR TO
          COMPLY WITH ANY APPLICABLE LAW, REGULATION, LEGAL PROCESS OR
          GOVERNMENTAL REQUEST.
          <br />
          USER AGREES AND UNDERSTANDS THAT, IN THE EVENT OF DISPUTE WITH ONE OR
          MORE USERS, Ekalsutra SHALL NOT BE HELD LIABLE FOR ANY CLAIMS,
          DAMAGES, DEMANDS(ACTUAL AND CONSEQENTIAL) ARISING OUT OF OR IN ANY WAY
          CONNECTED WITH SUCH DISPUTES, INCLUDING DAMAGES FOR LOSS OF PROFITS
          GOODWILL,PRIVACY OR DATA.
          <br />
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY THE
          USER FROM THE PLATFORM SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
          IN THE TERMS OF USE. NOTHING IN THE TERMS OF USE SHOULD BE CONSTRUED
          TO CONFER ANY RIGHTS TO THIRD PARTY BENEFICIARIES.
          <br />
          THE PLATFORM IS A PRODUCT OF PROVIDING A LINK FOR COMMUNICATION
          BETWEEN SCHOOLS, THEIR TEACHERS AND PARENTS. THE PLATFORM INCLUDES
          FEEDBACK, UPDATES, ADVICES, ANALYSIS, NOTIFICATIONS, PROVIDED BY THE
          TEACHERS TO THE PARENTS USING THIS PLATFORM. Ekalsutra DOES NOT
          GUARANTEE APTNESS, SUITABILITY, QUALITY, SUCCESS REGARDING ANY
          SUGGESTIONS SHARED THROUGH THIS PLATFORM. IT IS AGREED AND UNDERSTOOD
          BY THE USERS THAT, THE PLATFROM DOES NOT HAVE CONTROL OVER THE
          COMMUNICATIONS, IDEAS OR SUGGESTIONS ETC. PROVIDED THROUGH THE
          PLATFORM, AND Ekalsutra SHALL NOT BE HELD RESPONSIBLE FOR ANY RESULTS,
          FAILURES DUE TO SUCH COMMUNICATIONS ADVICES FEEDBACKS ON THE PLATFORM.
          <br />
          NO GUARANTEE CAN BE PROVIDED FOR THE COMPLETENESS AND ACCURACY OF
          THIRD PARTY INFORAMTION DEPICTED HEREIN. THIRD PARTY PROVIDERS ARE
          RESPONSIBLE FOR THEIR SUPPLIED CONTENT PURSUANT TO APPLICABLE LAW.
          Ekalsutra WILL NOT ASSUME RESPONSIBILITY FOR THE THIRDPARTY CONTENT OR
          CONTENT OF THIRD PARTY WEBSITES THAT ARE DEPICTED OR LINKED HEREIN.
          SUCH DEPICTION OR LINKS ARE MADE ACCESSIBLE FOR GENERAL REFERENCE
          PURPOSES ONLY. THIRD PARTY PROVIDERS ARE SOLELY RESPONSIBLE FOR THEIR
          ENTRIES. HENCE, THEY MAY UPDATE OR CHANGE THE CONTENTS OF THEIR
          WEBSITE AT ANY TIME WITHOUT THE PRIOR KNOWLEDGE OR CONSENT OF
          Ekalsutra. WE EXPRESSLY EMPHASIZE THAT WE DO NOT HAVE ANY INFLUENCE ON
          DESIGN OR CONTENT OF LINKED WEBSITES. WE, THEREFORE, DISASSOCIATE
          OURSELVES EXPRESSLY FROM ALL CONTENTS AVAILABLE FROM EXTERNAL SOURCES
          LINKED TO OUR HOMEPAGE. NOR DO WE ACKNOWLEDGE WHOLLY OR PARTLY
          EXTERNAL CONTENTS AS OUR OWN. THIS APPLIES TO ALL THE LINKED PAGES
          CONTAINED TO OUR HOMEPAGE, INCLUDING REFERENCES TO OTHER LINKED SITES.
          Ekalsutra RESERVES THE RIGHT TO AMEND OR COMPEMENT THE INFORMATION
          PROVIDED HEREIN WITHOUT PRIOR NOTICE AND, IF NECESSARY, TO REMOVE THE
          INFORMATION. THE USERS ARE THERBY REQUESTED, AT ALL TIMES, TO VERIFY
          THE CORRECTNESS OF THE PUBLISHED INFORMATION WITH THE RESPECTIVE
          SCHOOL. FAILURE TO DO SO WILL NOT GIVE RISE TO ANY CLAIM OR ACTION OF
          ANY NATURE AGAINST EKALSUTRA, BY ANY PARTY WHATSOEVER.
          <br />
          IT IS ALSO AGREED AND UNDERSTOOD THAT, ANY NOTICE OR UPDATES REGARDING
          ADMISSION TO ANY SCHOOL OR COURSES SHALL BE SUBJECT TO AVAILBILITY OF
          SEATS, GEOGRAPHICAL LOCATION AND OTHER DYNAMIC FUNCTIONS. THE PARENTS
          UNDERSTANDS AND ACKNOWLEDGES THAT THE PLATFORM ENDEVOURS TO SHARE
          INFORMATION, HOWEVER, ULTIMATE DECISION MAKING IS THEIR CHOICE AT
          THEIR OWN VOLITION AND DISCRETION. THE PLATFORM IS NOT LIABLE FOR ANY
          OUTCOME OF ADVICE GIVEN IN WHATSOEVER MANNER.
          <br />
          USERS AGREE AND UNDERSTAND THAT THEY SHALL NOT COMMUNICATE, POST,
          NOTIFY OR MAKE ADVICE REGARDING ANY INFORMATION WHICH IS FALSE,
          MALAFIDE WHICH MAY MISGUIDE THE OTHER USERS OR WHICH MAY RESULT INTO
          AFFCETING THE ACADEMIC PROGRESS OF THE STUDENTS. THE USERS SHALL NOT
          USE THE PLATFORM OR UPLOAD ANY CONTENTS WHICH HURT THE EMOTIONS OR
          SENTIMENTS OF OTHER USERS.
          <br />
          YOU UNDERSTAND AND ACKNOWLEDGE THAT THE SCHOOLS TEACHERS AND THE
          SERVICE PROVIDERS LISTED ON THE PLATFORM ARE NOT OUR AFFILIATES AND
          ARE INDEPENDENT ENTITIES, PROVIDING YOU THE SERVICES WHICH ARE AS PER
          YOUR REQUIREMENTS. WE ESPECIALLY DISCLAIM ANY OBLIGATIONS, LIABILITIES
          OR RESPONSIBILITIES IN RELATIONS TO ACADEMIC ADVICES, NOTIFICATIONS,
          UPDATES OR ANY OTHER SIMILAR ADVICE PROVIDED TO YOU THROUGH THE
          PLATFORM. WE DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, TIMELINESS,
          OR RELEVANCE OF SUCH ADVICE OR ANY SIMILAR INFORMATION PROVIDED
          THROUGH THE PLATFORM EKALSUTRA SHALL NOT ACCEPT ANY RESPONSIBILITY FOR
          ANY INTERACTION BETWEEN YOU AND THE RELEVANT THIRD PARTY AND IS
          RELEASED FROM ANY LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED
          WITH SUCH INTERACTION. Ekalsutra WILL NOT BE A PARTY TO OR IN ANY WAY
          BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND/OR OTHER
          MEMBERS AND/OR THIRD- PARTY PROVIDERS OF PRODUCTS OR SERVICES
        </Text>
        <Title mt={2}>Limitation of Liability</Title>
        <Text>
          USER UNDERSTAND AND AGREE THAT Ekalsutra SHALL NOT BE LIABLE TO THE
          USER FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
          EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
          PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
          Ekalsutra HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
          RESULTING FROM: (i) THE USE OR THE INABILITY TO USE , ITS SERVICE;
          (ii) PROCUREMENT OF GOODS AND SERVICES, INFORMATION OBTAINED OR
          MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
          PLATFORM AND/OR ITS SERVICE; (iii) ACCESS TO OR ALTERATION OF USER’S
          TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
          ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE PLATFORM
          AND/OR SERVICE.
        </Text>
        <Title mt={2}>Refund Policy</Title>
        <Text>
          Fees once paid through the payment gateway shall not be refunded other
          than in the following circumstances: Multiple debits from the End
          Users’ Card/Bank Account or End User's account being debited with
          excess amount in a single transaction due to system or technical
          error. It is clarified that if fees the transferred to Educational
          Institutes account, then the refunds shall be subject to the approval
          of the Educational Institutes. Ekalsutra and/or the Educational
          Institutes, shall, only in these cases, refund the excess amount
          excluding the payment gateway charges, including but not limited to
          TDR, service tax and convenience fees to be paid to the payment
          gateways, to the End User. The End Users understands that no refund
          shall be processed after a period of 180 days from the date of
          completion of the transaction for which they are seeking refund. The
          End User will have to make an application for refund along with the
          transaction number generated at the time of making payments. The
          application will be processed after verification, if the claim is
          found valid, the appropriate amount will be refunded by the Ekalsutra,
          through electronic mode in favour of the End User.
        </Text>
        <Title mt={2}>Chargebacks</Title>
        <Text>
          The Educational Institutions hereby agree that all refunds and
          chargebacks shall be their sole responsibility and Ekalsutra shall not
          be liable for any claims or disputes which may arise in connection
          with such refunds or chargebacks. The Educational Institutions agrees
          to indemnify Ekalsutra in respect of any claims, disputes, penalties,
          costs and expenses arising directly or indirectly in relation to
          refunds or chargebacks for all transactions initiated and instructed
          through Website.
        </Text>
        <Title mt={2}>Termination</Title>
        <Text>
          This Agreement will remain in full force and effect while the user use
          the Services. User may terminate User’s use of the Services or User’s
          account at any time by contacting us at info@ekalsutra.com Ekalsutra
          may suspend or terminate User’s access to the Services, Platform or
          User’s account at any time, for any reason (without cause or for
          User’s violation of any term of this Agreement), and without warning
          or notice, which may result in the forfeiture and destruction of all
          information associated with User’s membership. Upon termination of
          User’s account, User’s right to use the Services, access the Platform,
          and any Content will immediately cease. All provisions of this
          Agreement which, by their nature, should survive termination, will
          survive termination, including, without limitation, ownership
          provisions, warranty disclaimers, indemnity and limitations of
          liability.
        </Text>
        <Title mt={2}>Referral Policy:</Title>
        <Text>
          'Learn Together Earn Together' is a Referral Program on Ekalsutra App,
          which gives you an opportunity to invite your classmates to subscribe
          to Ekalsutra. Both you and your classmates get rewarded through the
          Referral Program.
          <br />
          For this Referral Program both Referrers and Referees need to be
          android users. Only Users with activated Ekalsutra membership will be
          eligible to share Referral Code with any other party who intend to
          avail the Services. The Referral Reward will be provided to the
          Referrer only upon activation of the Ekalsutra membership of the
          Referral Recipient.
          <br />
          The value of the Referral Reward will be as per Ekalsutra’s Referral
          Program, active at the time of Referral Recipient's purchase using the
          Referral Code. However, it is clarified that Ekalsutra reserves the
          right to exercise discretion with regards to issuance and withdrawal
          of Referral Reward to combat fraudulent transactions. A User can send
          unlimited referral invitations through this program however, the
          maximum extension one can earn through this program is limited to 6
          months. Ekalsutra reserves the right to change the limit anytime
          without any intimation. The usage of the Referral Reward may be
          subject to additional terms such as minimum plan amount, minimum
          number of referrals etc. Ekalsutra may notify such additional terms
          and applicability from time to time.
          <br />
          Ekalsutra reserves the right to discontinue the Referral Policy
          without assigning any reasons or without any prior intimation
          whatsoever to you. In case of any dispute, the Ekalsutra's decision
          shall be binding on you. Ekalsutra reserves the right to review and
          investigate all referral activities and to suspend accounts or revoke
          / remove Referral Reward and withhold features or benefits obtained
          through the Referral Program if the Ekalsutra believes or determines
          that the use of a Referral Code or Referral Reward was in error,
          fraudulent, illegal, or in violation of the Referral Terms. Any
          personal data collected for the purposes of the Referral Program will
          be processed in accordance with the provisions of the Privacy Policy.
        </Text>
        <Title mt={2}>General information</Title>

        <Text mt={1.5}>
          <span style={{ fontWeight: 600 }}>Entire Agreement:</span> The Terms
          of Use and the Privacy policy referenced herein constitute the entire
          agreement between the User and Ekalsutra and govern User’s visit
          and/or use of the Platform and the services, superseding any prior
          agreements or any other agreements, terms and conditions between User
          and Ekalsutra. User may be subject to additional terms and conditions
          that may apply when the User use or purchase certain other Ekalsutra
          services, affiliate services, third-party content or third-party
          software.
        </Text>
        <Text mt={1.5}>
          <span style={{ fontWeight: 600 }}>Choice of Law and Forum:</span> The
          Terms of Use and the relationship between User and Ekalsutra shall be
          governed by the laws of India without regard to its conflict of law
          and provisions. Any cause of action or claim User may have with
          respect to the Services or the Platform must be commenced within one
          (1) year after the claim or cause of action arises or else such claim
          or cause of action shall be deemed to be barred. Users of the Platform
          agrees to use binding arbitration for any claim, dispute, or
          controversy ("CLAIM") of any kind (whether in contract, tort or
          otherwise) arising out of or relating to the Platform, its terms of
          use and privacy policy. Arbitration shall be conducted pursuant to the
          rules and regulations of Indian Arbitration and conciliation Act 1996.
          Arbitration shall take place in English language and the seat of
          arbitration shall be at Mumbai, India. The users to the Platform
          hereby agree and submit themselves to the Arbitration and to the
          jurisdiction of the courts of Mumbai India for enforcing the
          arbitration award. Pursuant to the acceptance of arbitration, in no
          case shall the user, viewer, visitor, member, subscriber or customer
          have the right to go to the court and such rights are hereby waived by
          the users.
        </Text>
        <Text mt={1.5}>
          <span style={{ fontWeight: 600 }}>
            Waiver and Severability of Terms:
          </span>{" "}
          If any provision of the Terms of Use is found by a court of competent
          jurisdiction to be invalid, the parties nevertheless agree that the
          court should endeavour to give effect to the parties' intentions as
          reflected in the provision, and the other provisions of the Terms of
          Use remain in full force and effect. Any rights not expressly granted
          herein are reserved.
        </Text>
        <Text mt={1.5}>
          <span style={{ fontWeight: 600 }}>Assignment:</span> User may not
          assign any of User’s rights in these Terms, and any such attempt is
          void, but Ekalsutra may assign its rights to any of its affiliates or
          subsidiaries, or to any successor in interest of any business
          associated with the Services.
        </Text>
        <Text mt={1.5}>
          <span style={{ fontWeight: 600 }}>Force Majeure:</span> Without
          limiting the foregoing, under no circumstances shall Ekalsutra be held
          liable for any damage or loss, penalty due to delay or deficiency in
          performance of the Platform resulting directly or indirectly from acts
          of nature, forces, or causes beyond its reasonable control, including,
          without limitation, Internet failures, computer equipment failures,
          telecommunication failures, major electricity failures, internet
          outage, downtime, floods, storms, accident, civil disturbances, riots,
          strikes, shortages of labour, fluctuations in heat, light, fire or
          air.
        </Text>
        <Text mt={1.5}>
          <span style={{ fontWeight: 600 }}>No agency relationship:</span> User
          agree that no joint venture, partnership, employment, or agency
          relationship exists between User and Ekalsutra as a result of these
          Terms of Use or User’s use of the Platform and the Services.
        </Text>
      </ul>
    </Container>
  );
};

export default TermAndConditions;
const Text = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
`;
const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
`;
