import React from "react";
import styled from "styled-components";
import { Box, Typography, Button } from "@mui/material";

const AboutUs = () => {
  return (
    <Wrapper className="row justify-content-between py-5">
      <Box className="col-md-6 text-md-start text-center">
        <Box>
          <Typography className="section-heading py-2 px-4 m-auto m-md-0">
            Why Ekalsutra
          </Typography>

          <Typography className="heading mt-3">
            Why Ekalsutra School Management ERP SYSTEM?
          </Typography>
          <Typography className="desc mt-2">
            Ekalsutra ERP boasts numerous salient features essential for the
            seamless functioning of day-to-day tasks in schools. This advanced
            School ERP is a game-changer, enabling institutions to transition to
            a paperless environment while maintaining control over every report
            with just a click. Several compelling reasons make Ekalsutra ERP the
            preferred choice for schools, a few of which are highlighted below.
          </Typography>
          {/* <Button className="readMoreBtn text-capitalize">Read More</Button> */}
        </Box>
      </Box>
      <Box className="col-md-5 col-10 m-auto m-md-0 mt-5">
        <img className="w-100" src="/landingPage/about-us.svg" alt="About Us" />
      </Box>
    </Wrapper>
  );
};

export default AboutUs;

const textBluePrimary = "#31436D";
const Wrapper = styled.div`
  .heading {
    color: ${textBluePrimary};
    font-size: 36px;
    font-weight: 500;
    width: fit-content;
  }
  .bold {
    font-weight: 800;
  }

  .desc {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 30px;

    color: ${textBluePrimary};
    width: fit-content;
  }

  .desc2 {
    color: ${textBluePrimary};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 40px;
  }

  .section-heading {
    border-radius: 500px;
    border: 1px solid rgba(144, 156, 255, 0.3);
    background: #f5f6fe;
    color: #3347ed;
    width: fit-content;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .readMoreBtn {
    padding: 1.5rem;
    background-color: #2789fd;
    color: #f5f6fe;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    border-radius: 0.8rem;
    margin-top: 1rem;
  }

  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
      width: 100%;
    }
    .desc,
    .desc2 {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
    .readMoreBtn {
      font-size: 1.2rem;
    }
  }
`;
