import { React, useState, useEffect } from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import { Wrapper } from "./landing-page.style";
import WeProvide from "./WeProvide/we-provide";
import LeadingSchools from "./LeadingSchools/LeadingSchools";
import ModuleFeature from "./ModuleFeature/ModuleFeature";
import AboutUs from "./AboutUs/AboutUs";
import Guide from "./Guide/Guide";
import Testimonials from "./Testimonials/Testimonials";
import ExploreEkalsutra from "./ExploreEkalsutra/ExploreEkalsutra";
import GetInTouch from "./GetInTouch/GetInTouch";
import BestFeature from "./BestFeature/BestFeature";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
const LandingPage = (props) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that the effect runs once on mount

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Wrapper>
      {showButton && (
        <button id="button" onClick={scrollToTop}>
          <ArrowUpwardIcon
            style={{ fontSize: "2em", color: "white" }}
          ></ArrowUpwardIcon>
        </button>
      )}
      <Box className="d-flex justify-content-center head1">
        <Box className="py-5 mt-md-5 col-xxl-6">
          <Typography className="heading1" variant="h1" component="h2">
            Next Generation
          </Typography>
          <Typography className="heading2" variant="h1" component="h2">
            Schooling Solution
          </Typography>
          <Typography className="text-center desc1" variant="h3" component="h2">
            Best Quality of <span style={{ fontWeight: 800,display:"inline",margin:"inherit",padding:"inherit"}}>School Management System</span> in affordable price to all
            type of school.
          </Typography>

          <div className="d-flex justify-content-center mt-5">
            <Button
              className="px-lg-5 py-lg-4 px-4 py-3 startBtn mx-3"
              href="https://calendly.com/ekalsutra"
              target="_blank"
              rel="noreferrer noopener"
            >
              Book Demo
            </Button>
            <Button
              className="px-lg-5 py-lg-4 px-4 py-3 downloadBtn mx-3"
              href="https://play.google.com/store/apps/developer?id=Ekalsutra+Edtech+Pvt+Ltd&hl=en-IN"
              target="_blank"
            >
              Download App
            </Button>
          </div>
        </Box>
      </Box>
      <Box className="section2">
        <img
          className="col-md-6 col-12"
          src="/landingPage/homeImg.svg"
          alt=""
        />
      </Box>

      <Box>
        <LeadingSchools />
      </Box>
      <Box className="d-flex justify-content-center text-center mt-5">
        <Box className="col-lg-10">
          <WeProvide />
        </Box>
      </Box>
      <Box>
        <ModuleFeature />
      </Box>
      <Container>
        <AboutUs />
      </Container>
      <Box>
        <BestFeature />
      </Box>
      <Container>
        <Guide />
      </Container>
      <Box>
        <Testimonials />
      </Box>
      <Container>
        <ExploreEkalsutra />
      </Container>
      <Box className="position-relative py-3" id="contact-us">
        <Container className="px-md-3 px-0">
          <GetInTouch />
        </Container>
      </Box>
    </Wrapper>
  );
};

export default LandingPage;
