import { Typography } from "@mui/material";
import { React, useState } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useQuery } from "react-query";
import { getAllSchoolsLogo } from "../../../services/school.service";

const LeadingSchools = () => {
  const [schoolsLogoData, setSchoolsLogoData] = useState([]);

  const { schoolsLogoDataCall } = useQuery({
    queryKey: ["getSchoolCounts"],
    queryFn: () => getAllSchoolsLogo(),
    onSuccess: ({ data }) => {
      const schoolsLogo = data?.logoList;
      setSchoolsLogoData(schoolsLogo);
    },
  });

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <Wrapper className="text-center mt-5 mt-lg-0">
      <Typography
        sx={{
          color: "#31436D",
          fontSize: {
            xs: 20,
            lg: 36,
          },
          fontWeight: 700,
          letterSpacing: {
            xs: 1,
            lg: 1.8,
          },
        }}
      >
        490+ Leading School And Universities
      </Typography>
      <Carousel
        className="col-10 mb-5 py-5 mx-auto"
        responsive={responsive}
        autoPlay={true}
        infinite={true}
        autoPlaySpeed={3000}
      >
        {schoolsLogoData.map((item, index) => {
          return (
            <div
              className="schoolCard p-3 mx-3 row align-items-center justify-content-center"
              key="index"
            >
              <img src={item.schoolLogo} alt="School/University" />
            </div>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default LeadingSchools;

const Wrapper = styled.div`
  .react-multiple-carousel__arrow {
    background: #ffffff;
    border: 1px solid #0d213c33;
  }

  .react-multiple-carousel__arrow::before {
    color: black;
  }

  .react-multiple-carousel__arrow:hover {
    background: #2789fd;
  }
  .react-multiple-carousel__arrow:hover::before {
    color: #fff;
  }

  .schoolCard {
    border: 1px solid #0d213c33;
    border-radius: 2.1rem;
    aspect-ratio: 1/1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1.75rem 2rem -5px,
      rgba(0, 0, 0, 0.04) 0px 0.8rem 0.8rem -5px;
    img {
      object-fit: cover;
      aspect-ratio: 1/1;
      border-radius: 50%;
    }
  }
`;
