import React from "react";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";
const OurJourney = () => {
  return (
    <Wrapper className="row justify-content-md-between justify-content-center align-items-center flex-wrap-reverse py-5">
      <Box className="col-md-5 col-10 m-auto m-md-0 mt-5 text-center text-md-start">
        <img
          className="col-10"
          src="/landingPage/about-us/our-journey.svg"
          alt="Our Journey"
        />
      </Box>

      <Box className="col-md-7 text-md-start text-center">
        <Box>
          <Typography className="heading">
            Our <span className="bold">Jounrey</span>
          </Typography>
          <Typography className="desc mt-4">
            Our journey began with a deep-seated belief in the transformative
            power of technology in education. We recognized the complexities
            faced by schools—administrative burdens, communication gaps, and the
            need for efficient management systems. Ekalsutra was conceived as a
            solution to these challenges, aiming to empower educators,
            administrators, students, and parents alike.
          </Typography>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default OurJourney;
const textBluePrimary = "#31436D";
const Wrapper = styled.div`
  .heading {
    color: ${textBluePrimary};
    font-size: 36px;
    font-weight: 500;
    width: fit-content;
  }
  .bold {
    font-weight: 800;
  }

  .desc {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 40px;
    color: ${textBluePrimary};
    width: fit-content;
  }

  .readMoreBtn {
    padding: 1.5rem;
    background-color: #2789fd;
    color: #f5f6fe;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    border-radius: 0.8rem;
    margin-top: 1rem;
  }
`;
