import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
const OurStory = () => {
  return (
    <Grid container spacing={2} display={"flex"} alignItems={"center"}>
      {/* left section */}
      <Grid item xs={12} lg={6}>
        <CoutCardWrapper>
          <Grid container>
            <Grid item xs={6}>
              <SingleCountCardWrapper
                style={{
                  backgroundColor: "rgba(133, 253, 39, 0.05)",
                  borderRight: "1px solid rgba(13, 33, 60, 0.2)",
                  borderBottom: "1px solid rgba(13, 33, 60, 0.2)",
                  borderRadius: "25px 0 0 0",
                }}
              >
                <Box>
                  <Typography className="count_title">2 Lakh+</Typography>
                  <Typography className="count_disc">Active Users</Typography>
                </Box>
              </SingleCountCardWrapper>
            </Grid>
            <Grid item xs={6}>
              <SingleCountCardWrapper
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid rgba(13, 33, 60, 0.2)",
                  borderRadius: "0 25px 0 0",
                }}
              >
                <Box>
                  <Typography className="count_title">50+</Typography>
                  <Typography className="count_disc">Team Members</Typography>
                </Box>
              </SingleCountCardWrapper>
            </Grid>
            <Grid item xs={6}>
              <SingleCountCardWrapper
                style={{
                  backgroundColor: "#fff",
                  borderRight: "1px solid rgba(13, 33, 60, 0.2)",
                  borderBottom: "1px solid rgba(13, 33, 60, 0.2)",
                  borderRadius: "0 0 0 0",
                }}
              >
                <Box>
                  <Typography className="count_title">490+</Typography>
                  <Typography className="count_disc">Total School</Typography>
                </Box>
              </SingleCountCardWrapper>
            </Grid>
            <Grid item xs={6}>
              <SingleCountCardWrapper
                style={{
                  backgroundColor: "rgba(253, 155, 39, 0.05)",
                  borderBottom: "1px solid rgba(13, 33, 60, 0.2)",
                  borderRadius: "0 0 0 0",
                }}
              >
                <Box>
                  <Typography className="count_title">4 Year+</Typography>
                  <Typography className="count_disc">In Business</Typography>
                </Box>
              </SingleCountCardWrapper>
            </Grid>
            <Grid item xs={6}>
              <SingleCountCardWrapper
                style={{
                  backgroundColor: "rgba(146, 39, 253, 0.05)",
                  borderRight: "1px solid rgba(13, 33, 60, 0.2)",
                  borderRadius: "0 0 0 25px",
                }}
              >
                <Box>
                  <Typography className="count_title">20+</Typography>
                  <Typography className="count_disc">Award</Typography>
                </Box>
              </SingleCountCardWrapper>
            </Grid>
            <Grid item xs={6}>
              <SingleCountCardWrapper
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "0 0 25px 0",
                }}
              >
                <Box>
                  <Typography className="count_title">17K+</Typography>
                  <Typography className="count_disc">Total Staff</Typography>
                </Box>
              </SingleCountCardWrapper>
            </Grid>
          </Grid>
        </CoutCardWrapper>
      </Grid>
      {/* right section */}
      <Grid item xs={12} lg={6}>
        <TextSectionWrapper>
          <Typography className="head">Our Story</Typography>
          <Typography className="subhead">
            A Great story{" "}
            <span style={{ fontWeight: 700 }}>Starts with a Friendly team</span>
          </Typography>
          <Typography className="desc">
            The story of Ekalsutra began with a vision—a vision to transform the
            landscape of education through technological innovation. Founded by
            a team passionate about revolutionizing school management, Ekalsutra
            embarked on its journey with a singular goal: to empower educational
            institutions with a robust, user-friendly School Management ERP.
          </Typography>
          <Typography className="awarded_text">We Are Awarded By-</Typography>
          <img src="/landingPage/award.png" alt="award" />
        </TextSectionWrapper>
      </Grid>
    </Grid>
  );
};

export default OurStory;
const CoutCardWrapper = styled("div")`
  border-radius: 25px;
  border: 1px solid rgba(13, 33, 60, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(19, 70, 137, 0.05);
  background-color: #fff;
  width: 90%;
  margin: auto;
`;
const TextSectionWrapper = styled("div")`
  .head {
    color: #2789fd;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .subhead {
    color: #31436d;
    font-size: 3rem;
    font-weight: 500;
    line-height: 46px; /* 127.778% */
  }
  .desc {
    color: #31436d;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 40px; /* 250% */
  }
  .awarded_text {
    color: #31436d;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 51px; /* 231.818% */
  }
  img {
    height: 70px;
    object-fit: contain;
  }
`;
const SingleCountCardWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
  .count_title {
    color: #31436d;
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: 1.5px;
  }
  .count_disc {
    color: #31436d;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0.9px;
  }
`;
