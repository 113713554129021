import { useEffect, useState } from "react";
import React from "react";
import styled from "styled-components";
import { Typography, Box, Container } from "@mui/material";
import OurJourney from "./sub-components/OurJourney";
import OurVisionAndMission from "./sub-components/OurVisionAndMission";
import CoreTeam from "./sub-components/CoreTeam";
import OurTeam from "./sub-components/OurTeam";
import LetsStartDemo from "../shared/LetsStartDemo";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import OurStory from "./sub-components/OurStory";
const AboutUsPage = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that the effect runs once on mount

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Wrapper>
      {showButton && (
        <button id="button" onClick={scrollToTop}>
          <ArrowUpwardIcon
            style={{ fontSize: "2em", color: "white" }}
          ></ArrowUpwardIcon>
        </button>
      )}
      <Box className="aboutUsHeader py-5 row justify-content-center">
        <Box className="col-md-6">
          <Typography
            sx={{
              fontSize: {
                xs: 16,
                lg: 20,
              },
              fontWeight: 500,
            }}
          >
            About Us
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: 20,
                lg: 50,
              },
              fontWeight: 700,
            }}
          >
            We’re on a mission to expand the potential of education.
          </Typography>
        </Box>
      </Box>
      <Container>
        <Box className="row justify-content-center align-items-center py-5">
          <img
            className="col-8"
            src="/landingPage/about-us/about-us-pc.svg"
            alt="Computer"
          />
        </Box>
        <Typography className="heading mt-5 text-center">
          Ekalsutra Management{" "}
          <span className="bold">A Smart Education System</span>
        </Typography>
        <Typography className="desc text-center">
          Welcome to Ekalsutra Education Management System, We are one of the
          market leading company who provides world-class global service to its
          associated Clients, also we provide consistent support and help to the
          Clients in better understanding of our products and services. We are
          the team of young and passionate people who are ready to face tough
          challenges on the path of success. We are the number one source in
          Educational ERP Management System for international Schools, Colleges,
          Universities and Institutes. We're dedicated in providing the prime
          quality.
        </Typography>
      </Container>

      <Container>
        <OurJourney />
      </Container>

      <Container className="py-5">
        <OurVisionAndMission />
      </Container>

      <Box className="what-we-believe row justify-content-center">
        <Box className="col-md-10 py-5">
          <Typography className="heading">
            What We <span className="bold">Belive?</span>
          </Typography>
          <Typography className="desc text-center pb-5">
            At Ekalsutra, our beliefs form the foundation of everything we do.
            We are driven by a set of core values that shape our interactions,
            decisions, and the way we approach the development of our School
            Management ERP. These beliefs reflect our commitment to excellence,
            innovation, and the transformative power of education technology.
          </Typography>
        </Box>
      </Box>

      <Container className="py-5">
        <OurStory />
      </Container>
      <Container className="py-5">
        <CoreTeam />
      </Container>

      <Container className="py-5">
        <OurTeam />
      </Container>

      <Container className="px-0">
        <LetsStartDemo />
      </Container>
    </Wrapper>
  );
};

export default AboutUsPage;
const textBluePrimary = "#31436D";
const textBlueSecondary = "#1A4E94";

const Wrapper = styled.div`
  .heading {
    color: ${textBluePrimary};
    font-size: 36px;
    font-weight: 500;
  }
  .bold {
    font-weight: 800;
  }

  .desc {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 40px;
    color: ${textBluePrimary};
  }

  .desc2 {
    color: ${textBluePrimary};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 40px;
  }

  .aboutUsHeader {
    text-align: center;
    color: #31436d;
    background-image: url("/landingPage/about-us/about-us-head.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .what-we-believe {
    text-align: center;
    color: #31436d;
    background-image: url("/landingPage/about-us/about-us-head.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  #button {
    display: inline-block;
    background-color: ${textBlueSecondary};
    width: 4.5rem;
    height: 4.5rem;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    z-index: 1000;
    border: 0;
  }

  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
    }
    .desc {
      font-size: 1rem;
      line-height: 1.75rem;
    }
    .desc2 {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }
`;
