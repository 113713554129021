import React from "react";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";

const Guide = () => {
  return (
    <Wrapper className="row justify-content-md-between justify-content-center flex-wrap-reverse py-5">
      <Box className="col-md-5 col-10 m-auto m-md-0 mt-5 text-center text-md-start">
        <img className="w-75" src="/landingPage/guide.svg" alt="About Us" />
      </Box>

      <Box className="col-md-6 text-md-start text-center">
        <Box>
          <Typography className="section-heading py-2 px-4 m-auto m-md-0">
            Get Free Guide
          </Typography>

          <Typography className="heading mt-2">
            Looking for the{" "}
            <span className="bold">
              Best <br /> School Management{" "}
            </span>
            Software?
          </Typography>
          <Typography className="desc mt-2">
            Our school management ERP system is designed to revolutionize the
            educational experience. With seamless integration of tools for
            administration, communication, and learning, Ekalsutra empowers
            educators, students, and parents alike. From simplified attendance
            tracking to interactive lesson planning, our user-friendly interface
            fosters collaboration and efficiency. Dive into a world of
            comprehensive analytics, personalized learning paths, and holistic
            progress monitoring. Join us on this transformative journey where
            education transcends boundaries and embraces technology to create a
            dynamic, engaging, and enriching learning environment for all.
          </Typography>
          {/* <Typography className="desc">
            Here is a quick guide on must-have features in{" "}
            <span className="bold">School management Software.</span> And is it
            worth investing in?
          </Typography> */}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Guide;

const textBluePrimary = "#31436D";
const Wrapper = styled.div`
  .heading {
    color: ${textBluePrimary};
    font-size: 36px;
    font-weight: 500;
    width: fit-content;
  }
  .bold {
    font-weight: 800;
  }

  .desc {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 30px;
    color: ${textBluePrimary};
    width: fit-content;
  }

  .section-heading {
    border-radius: 500px;
    border: 1px solid rgba(144, 156, 255, 0.3);
    background: #f5f6fe;
    color: #3347ed;
    width: fit-content;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .readMoreBtn {
    padding: 1.5rem;
    background-color: #2789fd;
    color: #f5f6fe;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    border-radius: 0.8rem;
    margin-top: 1rem;
  }

  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
      width: 100%;
    }
    .desc,
    .desc2 {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
    .readMoreBtn {
      font-size: 1.2rem;
    }
  }
`;
