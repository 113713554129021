import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import Carousel from "react-multi-carousel";

const ModuleFeature = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const imageArray = [
    "/landingPage/features/mobile_1.png",
    "/landingPage/features/mobile_2.png",
    "/landingPage/features/mobile_3.png",
    "/landingPage/features/mobile_4.png",
    "/landingPage/features/mobile_5.png",
    "/landingPage/features/mobile_6.png",
  ];
  return (
    <Wrapper className="py-5">
      <Typography className="text-center pt-4">
        <span className="section-heading py-2 px-4">Modules & Features</span>
      </Typography>
      <Typography className="heading mt-5">
        {/* We Provide <span className="bold">Staff</span> and{" "}
        <span className="bold">Parent</span> Mobile App */}
        We provide <h3 className="bold heading mt-5">Ekalsutra's Staff app</h3> and <h3 className="bold heading mt-5">Parents app</h3>.
      </Typography>
      <Typography className="desc text-center mt-4">
         Experience Seamless <h3 className="bold" style={{fontSize:"14px"}}>School Management</h3> On-The-Go!
      </Typography>

      <Carousel
        className="col-10 mb-5 py-5 mx-auto"
        autoPlay={true}
        infinite={true}
        autoPlaySpeed={3000}
        arrows={false}
        responsive={responsive}
      >
        {imageArray.map((item, index) => {
          return (
            <div
              className="imageCard mx-3 d-flex align-items-center justify-content-center"
              key={index}
            >
              <img className="w-100" src={item} alt="features" />
            </div>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default ModuleFeature;

const textBluePrimary = "#31436D";
export const Wrapper = styled.div`
  background-color: #eef6ff;
  .heading {
    color: ${textBluePrimary};
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .bold {
    font-weight: 800;
    display:inline;
  }

  .desc {
    color: ${textBluePrimary};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 27px;
  }

  .section-heading {
    border-radius: 500px;
    border: 1px solid rgba(144, 156, 255, 0.3);
    background: #f5f6fe;
    color: #3347ed;
    font-size: 1.2rem;
    font-weight: 700;
  }
  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
    }
    .desc {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }
`;
