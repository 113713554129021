import React from "react";
import styled from "styled-components";
import { Typography, Box } from "@mui/material";
const CoreTeam = () => {
  return (
    <Wrapper>
      <Typography className="heading text-center">
        Core <span className="bold">Team</span>
      </Typography>
      <Typography className="desc text-center">
        Leadership Team - In Existence Since 2019
      </Typography>
      <Box className="d-flex flex-md-nowrap flex-wrap justify-content-between mt-5 pt-lg-5">
        <Box className="card p-4">
          <div className="d-flex justify-content-between align-items-center">
            <img
              className="profile-pic"
              src="/landingPage/team/ankit-mar.png"
              alt="icon"
            />
            <div className="social-media-icons">
              <a
                href="https://www.linkedin.com/in/ankit-jaiswal-1449b0248/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-active.png"
                    className="active"
                  />
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
            </div>
          </div>
          <Box className="h-100 p-3 pt-lg-5 mt-lg-4">
            <Typography
              sx={{
                fontSize: 26,
                fontWeight: 700,
              }}
            >
              Ankit Jaiswal
            </Typography>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Co-Founder & CFO
            </Typography>
            <Typography className="desc mt-3" sx={{ opacity: "70%" }}>
              Mr. Ankit Jaiswal has Sound knowledge of Finance and Stock Market.
              <br />
              As you know, Finance is heartbeat of every organization, where
              fiscal responsibility and strategic financial leadership converge.
              Meet our Chief Financial Officer (CFO), the steward of our
              financial health and architect of sustainable growth. With an
              unwavering commitment to fiscal integrity, Mr. Ankit Jaiswal plays
              a central role in steering the financial course of our company.
              Beyond numbers and balance sheets, Mr. Ankit Jaiswal brings a
              unique blend of strategic acumen and a keen understanding of
              market dynamics. Explore the intricate dance between risk and
              reward, investment and return.
            </Typography>
          </Box>
        </Box>

        <Box className="card p-4">
          <div className="d-flex justify-content-between align-items-center">
            <img
              className="profile-pic"
              // src="/landingPage/team/chandan-dev.png"
              src="/landingPage/team/prakash-dev.png"
              alt="icon"
            />
            <div className="social-media-icons">
              <a
                href="https://www.linkedin.com/company/ekalsutraofficial/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-active.png"
                    className="active"
                  />
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
            </div>
          </div>
          <Box className="h-100 p-3 pt-lg-5 mt-lg-4">
            <Typography
              sx={{
                fontSize: 26,
                fontWeight: 700,
              }}
            >
              {/* Chandan Maurya */}
              Prakash Kumar
            </Typography>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Co-Founder & CPE
            </Typography>
            {/* <Typography className="desc mt-3" sx={{ opacity: "70%" }}>
              Mr. Maurya is a strong team leader and highly customer centric
              entrepreneur.
              <br />
              The driving force behind our technological advancements and
              strategic vision. With a passion for harnessing the power of
              cutting-edge solutions, our CTO plays a pivotal role in shaping
              the digital landscape of our company. Meet the architect of our
              technological future, a visionary leader committed to pushing
              boundaries, fostering innovation, and ensuring that we remain at
              the forefront of industry evolution. Explore the seamless
              integration of talent, technology, and transformation under the
              guidance of our CTO, as we continue to redefine possibilities and
              exceed expectations in the ever-evolving world of technology
            </Typography> */}
            <Typography className="desc mt-3" sx={{ opacity: "70%" }}>
              With a passion for pushing the boundaries of what's possible, our
              Chief Product Engineer "Mr Prakash" leads a dynamic team dedicated
              to creating cutting-edge solutions that redefine industries.
              <br />
              At the helm of our product development, our Chief Product Engineer
              combines technical prowess with a keen understanding of market
              trends, ensuring that each creation not only meets but exceeds the
              expectations of our diverse clientele. With a wealth of experience
              in Product Development, our Chief Product Engineer brings a unique
              blend of vision and practicality to every project, driving our
              commitment to delivering solutions that stand out in today's
              competitive landscape.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CoreTeam;
const MediaBox = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(8, 76, 132, 0.2);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  .inactive {
    display: none;
  }
  .active {
    display: block;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  &:hover {
    background-color: rgba(39, 137, 253, 1);
    border: 0.5px solid rgba(39, 137, 253, 1);
    .inactive {
      display: block;
    }
    .active {
      display: none;
    }
  }
`;
const Wrapper = styled.div`
  .card {
    border-radius: 1.7rem;
    border: 1px solid rgba(13, 33, 60, 0.2);
    box-shadow: none;
    width: 48%;
    position: relative;
    .profile-pic {
      position: absolute;
      object-fit: cover;
      width: 10rem;
      aspect-ratio: 1/1;
      border: 0.3rem solid #2789fd;
      border-radius: 1rem;
      top: -5rem;
      left: 2rem;
    }
    .social-media-icons {
      position: absolute;
      right: 0;
      top: 1rem;
      right: 1rem;
    }
    .social-media {
      img {
        transform: scale(3.5);
      }
    }
  }
  p {
    color: #31436d;
  }
  .heading {
    font-size: 36px;
    font-weight: 500;
  }
  .bold {
    font-weight: 700;
  }
  .desc {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
  }
  img {
    width: 6rem;
  }

  @media (max-width: 992px) {
    .card {
      width: 100%;
      margin-top: 1.5rem;
      .social-media-icons {
        position: relative;
        right: 0;
        top: 0;
      }
      .profile-pic {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
`;
