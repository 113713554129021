import React from "react";
import styled from "styled-components";
import { Box, Typography, Button } from "@mui/material";
const LetsStartDemo = () => {
  return (
    <Wrapper className="row flex-wrap align-items-center justify-content-around text-lg-start text-center mb-1">
      <Box className="col-lg-6 mb-lg-0 mb-4">
        <Typography
          sx={{
            fontSize: {
              xs: 16,
              lg: 40,
            },
            fontWeight: 700,
          }}
        >
          Ready to Grow Your
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 26,
              lg: 56,
            },
            fontWeight: 700,
          }}
        >
          School Digitally?
        </Typography>
      </Box>
      <Button
        className="col-lg-3 col-6"
        href="https://calendly.com/ekalsutra"
        target="_blank"
        variant="contained"
        color="secondary"
        rel="noreferrer noopener"
        size="large"
        sx={{
          border: "1px solid #fff",
          boxShadow: "none",
          borderRadius: "10px",
          fontSize: "14px",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        Let's start the Demo
      </Button>
    </Wrapper>
  );
};

export default LetsStartDemo;
const Wrapper = styled.div`
  @media (min-width: 968px) {
    border-radius: 2.1rem;
  }
  background-color: #2789fd;
  padding: 3rem 0;
  p {
    color: #fff;
    line-height: 79px;
  }
  button {
    text-transform: capitalize;
    color: #2789fd;
    background-color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8rem;
    border-radius: 1.25rem;
    padding: 1.5rem;
  }
  @media (max-width: 992px) {
    p {
      line-height: 36px;
    }
    button {
      border-radius: 7px;
      font-size: 1.2rem;
    }
  }
`;
