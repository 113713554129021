import { QueryClient, QueryClientProvider } from "react-query";
import RootRoutes from "./routing";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@mui/material";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#0C2F49",
    },
    secondary: {
      main: "rgba(39, 137, 253, 1)",
    },
    text: {
      primary: "#0C2F49",
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MaterialThemeProvider theme={theme}>
        <RootRoutes />
      </MaterialThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
