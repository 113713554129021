import React from "react";
import styled from "styled-components";
import { Box, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
const ExploreEkalsutra = () => {
  return (
    <Wrapper className="row justify-content-between py-5">
      <Box className="col-md-6 text-md-start text-center">
        <Box>
          <Typography className="section-heading py-2 px-4 m-auto m-md-0">
            Explore Our Most Advanced
          </Typography>

          <Typography className="heading mt-3">
            {/* Ekalsutra <span className="bold">School Mobile App</span> */}
            Effortless <h3 className="bold" style={{fontSize:"inherit",display:"inline"}}>School Management</h3> with Ekalsutra’s Mobile Apps!
          </Typography>
          <Typography className="desc2 mt-3">
            The Ekalsutra School Mobile App is a comprehensive solution that
            seamlessly integrates and optimizes a wide array of fundamental
            school-related functions, enabling access from any location and at
            any time, all accomplished effortlessly with a simple tap on the
            screen.
          </Typography>
          <Stack direction={"row"} justifyContent={"center"}>
            <a
              className="redirect-play"
              href="https://play.google.com/store/apps/developer?id=Ekalsutra+Edtech+Pvt+Ltd&hl=en-IN"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/landingPage/google-play-badge.png" alt="" />
            </a>
          </Stack>
        </Box>
      </Box>
      <Box className="col-md-4 col-10 m-auto m-md-0 mt-5">
        <div className="w-100 m-auto position-relative">
          <img
            src="/landingPage/explore.png"
            alt="explore"
            className="w-100 m-auto"
          />
          <a
            className="text1 my-2"
            href="https://play.google.com/store/apps/details?id=com.ekalsutraparentapp&pcampaignid=web_share"
            target="_blank"
            rel="noreferrer noopener"
            title="Click to download"
          >
            <div className="app_box1_root app_box1">
              <img src="/landingPage/hand-with-mobile.png" alt="hand" />

              <Typography className="typo">Parent App</Typography>
            </div>
          </a>
          <a
            className="text1 my-2"
            href="https://play.google.com/store/apps/details?id=com.ekalsutrastaffapp&pcampaignid=web_share"
            target="_blank"
            rel="noreferrer noopener"
            title="Click to download"
          >
            <div className="app_box1_root app_box2">
              <img src="/landingPage/hand-with-mobile.png" alt="hand" />
              <Typography className="typo">Staff App</Typography>
            </div>
          </a>
          <Box
            className="rating_box"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Typography className="typo">Overall Rating</Typography>
            <Typography className="typo_number">4.5</Typography>
            <Stack direction={"row"} gap={0.5} justifyContent={"center"}>
              <StarIcon className="star_color" />
              <StarIcon className="star_color" />
              <StarIcon className="star_color" />
              <StarIcon className="star_color" />
              <StarHalfIcon className="star_color" />
            </Stack>
            <Typography className="typo">Based on 10,000+ Reviews</Typography>
          </Box>
        </div>
      </Box>
    </Wrapper>
  );
};

export default ExploreEkalsutra;

const textBluePrimary = "#31436D";
const Wrapper = styled.div`
  .redirect-play {
    img {
      width: 20rem;
    }
  }
  .heading {
    color: ${textBluePrimary};
    font-size: 36px;
    font-weight: 500;
    width: fit-content;
  }
  .bold {
    font-weight: 800;
  }

  .desc {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 30px;
    color: ${textBluePrimary};
    width: fit-content;
  }

  .desc2 {
    color: ${textBluePrimary};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 40px;
  }
  .app_box1_root {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 5px;
    display: flex;
    gap: 5px;
    align-items: center;

    .typo {
      font-size: 14px;
      font-weight: 600;
      margin-right: 5px;
      color: #31436d;
    }

    img {
      height: 30px;
      width: 30px;
      object-fit: contain;
    }
  }
  .app_box1 {
    position: absolute;
    top: 0;
    margin-top: 30px;
  }
  .app_box2 {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 50px;
    margin-right: 10px;
  }
  .rating_box {
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    .typo {
      font-weight: 500;
      text-align: center;
      color: #31436d;
      opacity: 0.75;
    }
    .typo_number {
      font-weight: 700;
      text-align: center;
      font-size: 30px;
      color: #31436d;
    }
  }
  .section-heading {
    border-radius: 500px;
    border: 1px solid rgba(144, 156, 255, 0.3);
    background: #f5f6fe;
    color: #3347ed;
    width: fit-content;
    font-size: 1.25rem;
    font-weight: 700;
  }
  .star_color {
    color: #ff9f0f;
  }
  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
      width: 100%;
    }
    .desc,
    .desc2 {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }
`;
