import styled from "styled-components";

const textBluePrimary = "#31436D";
const textBlueSecondary = "#1A4E94";
export const Wrapper = styled.div`
  .head1 {
    padding-top: 3rem;
    background-image: url("/landingPage/homeBg1.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    .heading1 {
      font-size: 5.5rem;
      font-weight: 700;
      line-height: 7.2rem;
      letter-spacing: 0.1em;
      text-align: center;
      color: ${textBluePrimary};
    }
    .heading2 {
      font-size: 5.5rem;
      font-weight: 900;
      line-height: 7.2rem;
      letter-spacing: 0.1em;
      text-align: center;
      color: ${textBlueSecondary};
    }
    .desc1 {
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 4.25rem;
    }
    .startBtn,
    .downloadBtn {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.75rem;
      letter-spacing: 0.1em;
    }
    .startBtn {
      background: #2789fd;
      color: #fff;
      border-radius: 0.8rem;
      border: 1px;
      text-transform: capitalize;
    }
    .downloadBtn {
      color: #2789fd;
      border: 2px solid #2789fd;
      background: transparent;
      border-radius: 0.8rem;
      text-transform: capitalize;
    }
  }

  .section2 {
    overflow-x: hidden;
    padding-top: 10rem;
    text-align: center;
    img {
      transform: translate(0, -25%);
    }
  }

  .section2::before {
    content: "";
    width: 100%;
    left: 0%;
    right: 0%;
    background: #1a4e94;
    height: 100%;
    position: absolute;
    z-index: -1;
    clip-path: polygon(100% 0%, 100% 40%, 0 60%, 0 20%);
  }

  @media (max-width: 968px) {
    .heading1,
    .heading2 {
      font-size: 2rem !important;
      line-height: 2.75rem !important;
      letter-spacing: 0 !important;
    }
    .heading2 {
      font-size: 2.25rem !important;
    }
    .desc1 {
      font-size: 1.2rem !important;
      line-height: 1.9rem !important;
    }
    .startBtn,
    .downloadBtn {
      font-size: 1.2rem !important;
      line-height: 1.2rem !important;
      letter-spacing: 0 !important;
    }

    .section2 {
      overflow-x: hidden;
      padding-top: 1rem;
      text-align: center;
      background-color: #1a4e94;
      img {
        transform: translate(0, 0%);
      }
    }
    .section2::before {
      display: none;
    }
  }

  #button {
    display: inline-block;
    background-color: ${textBlueSecondary};
    width: 4.5rem;
    height: 4.5rem;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    z-index: 1000;
    border: 0;
  }
`;
