import axios from "axios";

const public_route = `${process.env.REACT_APP_ORG_BASE}/public`;
export const getAllSchoolsLogo = () => {
  return axios.get(`${public_route}/getAllSchoolLogo`);
};

export const getAllTestimonials = () => {
  return axios.get(`${public_route}/getAllActiveTestimonials`);
};
export const contactUs = (body) => {
  return axios.post(`${public_route}/contactUs`, body);
};
