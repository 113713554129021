import React from "react";
import styled from "styled-components";
import { Card, CardContent, Typography } from "@mui/material";
const OurVisionAndMission = () => {
  return (
    <Wrapper className="d-flex flex-md-nowrap flex-wrap justify-content-between">
      <Card className="card p-4">
        <CardContent className="h-100 p-5">
          <Typography className="heading">
            <img src="/landingPage/about-us/our-vision.svg" alt="icon" />
            Our Vision
          </Typography>
          <Typography className="desc">
            Ekalsutra envisions a future where every educational institution,
            regardless of size or location, has access to a state-of-the-art
            School Management ERP that enhances efficiency, transparency, and
            collaboration. We aspire to be a catalyst for positive change in
            education by creating a holistic ecosystem that supports educators,
            administrators, and students alike. Our vision is to be a trusted
            partner in shaping the future of education, where institutions
            thrive and learners excel.
          </Typography>
        </CardContent>
      </Card>

      <Card className="card p-4">
        <CardContent className="h-100 p-5">
          <Typography className="heading">
            <img src="/landingPage/about-us/our-mission.svg" alt="icon" />
            Our Mission
          </Typography>
          <Typography className="desc">
            At Ekalsutra School Management ERP, our mission is to revolutionize
            the education landscape by providing innovative, user-friendly, and
            scalable solutions for school management. We are committed to
            empowering educational institutions with cutting-edge technology,
            fostering a seamless administrative and academic environment. Our
            mission is anchored in the belief that efficient school management
            is the cornerstone of a successful and nurturing learning
            experience.
          </Typography>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default OurVisionAndMission;
const Wrapper = styled.div`
  .card {
    border-radius: 136px 0px 221.97px 0px;
    border: 1px solid rgba(13, 33, 60, 0.2);
    box-shadow: none;
    width: 48%;
    img {
      width: 8rem;
    }
  }
  p {
    color: #31436d;
  }
  .heading {
    font-size: 36px;
    font-weight: 700;
  }
  .desc {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 40px;
  }

  @media (max-width: 968px) {
    .card {
      width: 100%;
      margin-top: 1.5rem;
      img {
        width: 6rem;
      }
    }
  }
`;
