export const ROUTING_NAMES = {
  home: "/",
  services: "/services",
  features: "/features",
  pricing: "/pricing",
  aboutUs: "/about-us",
  careers: "/careers",
  contact_us: "/contact-us",
  refundPolicy: "/refund-policy",
  govermentCertified: "/goverment-certified",
  termAndConditions: "/term-and-conditions",
  privacyPolicy: "/privacy-policy",
  security: "/security",
  blogs :"/blogs",
};
