import React from "react";
import { Typography, Box } from "@mui/material";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const leftData = [
  {
    id: 1,
    name: "Anytime Anywhere Accessibility",
    image: "/landingPage/features/efficient.png",
    description:
      "The cloud-based infrastructure of Ekalsutra ERP enables ubiquitous accessibility, facilitating usage from anywhere any time, contingent upon an internet connection. ",
  },
  {
    id: 2,
    name: "Data Security and Backup ",
    image: "/landingPage/features/easy.png",
    description:
      "Cloud-based services save data in the cloud rather than in storage facilities, so data can be easily retrieved. Data backup is automatic and schools do not need to invest in hardware to store data. Additionally, IP restrictions, OTP authentication, role-based user access and identity data are stored using our ERP. ",
  },
  {
    id: 3,
    name: "Easy data migration",
    image: "/landingPage/features/tracking.png",
    description:
      "Ekalsutra ERP's implementation is carefully planned; ensuring swift setup does not take much time. The system incorporates a data porting feature, simplifying the migration of previous years' records seamlessly.",
  },
  {
    id: 4,
    name: "Easy and Faster Communication",
    image: "/landingPage/features/friendly.png",
    description:
      "he ERP eliminates the need for parents to visit the school frequently to meet with class or subject teachers regarding their ward's progress. Teachers can effortlessly send daily remarks about students through the system, enabling quicker and more efficient communication between the school and parents. This significantly reduces the communication gap, fostering better and more immediate connections. ",
  },
  {
    id: 5,
    name: "Multi-School Management",
    image: "/landingPage/features/easy.png",
    description:
      "Ekalsutra ERP is a platform that offers a unique and powerful feature, allowing the management of multiple schools or educational institutions within a single system. This robust feature is designed to support and streamline diverse activities across various educational establishments. With Ekalsutra ERP, it's easy to efficiently oversee and operate multiple schools simultaneously. ",
  },
  {
    id: 6,
    name: "Paperless Work & Better Reporting",
    image: "/landingPage/features/transparency.png",
    description:
      "There is no doubt that school work requires a lot of pen and paper. Sometimes it is difficult to do things without software because it has many limitations such as data being lost or damaged. However, using Ekalsutra ERP eliminates all these restrictions. ERP provides many features needed by school management like Price announcements, registration information and other miscellaneous notifications. Our ERP provides better reporting to thousands of users with zero downtime. ",
  },
  {
    id: 7,
    name: "Superlative Customer Support",
    image: "/landingPage/features/tracking.png",
    description:
      "We prioritize delivering top-notch assistance and effective resolutions for software-related issues. Our dedicated technical support team for the parent help desk is equipped to address parent queries, be it login problems or fee-related issues. Resolving these concerns promptly is our commitment, ensuring high satisfaction rates. Ekalsutra School ERP serves as an all-in-one solution, streamlining processes seamlessly and establishing credibility. ",
  },
];
const rightData = [
  {
    id: 8,
    name: "Anytime Anywhere Accessibility",
    image: "/landingPage/features/efficient.png",
    description:
      "The cloud-based infrastructure of Ekalsutra ERP enables ubiquitous accessibility, facilitating usage from anywhere any time, contingent upon an internet connection. ",
  },
  {
    id: 9,
    name: "Data Security and Backup ",
    image: "/landingPage/features/easy.png",
    description:
      "Cloud-based services save data in the cloud rather than in storage facilities, so data can be easily retrieved. Data backup is automatic and schools do not need to invest in hardware to store data. Additionally, IP restrictions, OTP authentication, role-based user access and identity data are stored using our ERP. ",
  },
  {
    id: 10,
    name: "Easy data migration",
    image: "/landingPage/features/tracking.png",
    description:
      "Ekalsutra ERP's implementation is carefully planned; ensuring swift setup does not take much time. The system incorporates a data porting feature, simplifying the migration of previous years' records seamlessly.",
  },
  {
    id: 11,
    name: "Easy and Faster Communication",
    image: "/landingPage/features/friendly.png",
    description:
      "he ERP eliminates the need for parents to visit the school frequently to meet with class or subject teachers regarding their ward's progress. Teachers can effortlessly send daily remarks about students through the system, enabling quicker and more efficient communication between the school and parents. This significantly reduces the communication gap, fostering better and more immediate connections. ",
  },
  {
    id: 12,
    name: "Multi-School Management",
    image: "/landingPage/features/easy.png",
    description:
      "Ekalsutra ERP is a platform that offers a unique and powerful feature, allowing the management of multiple schools or educational institutions within a single system. This robust feature is designed to support and streamline diverse activities across various educational establishments. With Ekalsutra ERP, it's easy to efficiently oversee and operate multiple schools simultaneously. ",
  },
  {
    id: 13,
    name: "Paperless Work & Better Reporting",
    image: "/landingPage/features/transparency.png",
    description:
      "There is no doubt that school work requires a lot of pen and paper. Sometimes it is difficult to do things without software because it has many limitations such as data being lost or damaged. However, using Ekalsutra ERP eliminates all these restrictions. ERP provides many features needed by school management like Price announcements, registration information and other miscellaneous notifications. Our ERP provides better reporting to thousands of users with zero downtime. ",
  },
  {
    id: 14,
    name: "Superlative Customer Support",
    image: "/landingPage/features/tracking.png",
    description:
      "We prioritize delivering top-notch assistance and effective resolutions for software-related issues. Our dedicated technical support team for the parent help desk is equipped to address parent queries, be it login problems or fee-related issues. Resolving these concerns promptly is our commitment, ensuring high satisfaction rates. Ekalsutra School ERP serves as an all-in-one solution, streamlining processes seamlessly and establishing credibility. ",
  },
];

const BestFeature = () => {
  const [leftElements, setLeftElements] = useState([]);
  const [rightElements, setRightElements] = useState([]);
  const [active, setActive] = useState(leftData[0]);

  useEffect(() => {
    const leftNum = 7;
    const angleIncrement = Math.PI / (leftNum + 1); // Distribute along half of the circle
    const leftElementsArray = leftData.map((item, index) => {
      const angle = angleIncrement * (index + 1);
      const elementStyle = {
        right: `${42.5 - 50 * -Math.sin(angle)}%`,
        top: `${42 - 50 * Math.cos(angle)}%`,
      };
      return (
        <div
          key={index}
          className={`element ${active.id === item.id ? "active-element" : ""}`}
          style={elementStyle}
          onMouseEnter={() => setActive(item)}
        >
          <p className="left">{item.name}</p>
          <div className="icon-bubble icon-bubble-left">
            <img className="col-8" src={item.image} alt="" />
          </div>
        </div>
      );
    });

    setLeftElements(leftElementsArray);

    const rightElementsArray = rightData.map((item, index) => {
      const angle = Math.PI + angleIncrement * (index + 1);
      const elementStyle = {
        left: `${47.5 - 50 * Math.sin(angle)}%`,
        top: `${42 - 50 * Math.cos(angle)}%`,
      };
      return (
        <div
          key={index + leftNum}
          className={`element ${active.id === item.id ? "active-element" : ""}`}
          style={elementStyle}
          onMouseEnter={() => setActive(item)}
        >
          <div className="icon-bubble icon-bubble-right">
            <img className="col-8" src={item.image} alt="" />
          </div>
          <p className="right">{item.name}</p>
        </div>
      );
    });

    setRightElements(rightElementsArray);
  }, [active]);

  return (
    <Wrapper className="py-5">
      <Typography className="text-center pt-4">
        <span className="section-heading py-2 px-4">Best Features</span>
      </Typography>
      <Typography className="heading mt-5">
        Our Most <span className="bold">Popular Features</span>
      </Typography>

      {/* Large screens */}
      <Box className="row justify-content-center align-items-center pt-5 d-lg-flex d-none">
        <div className="outer-box row justify-content-center align-items-center position-relative">
          <div className="inner-box text-center">
            <i></i>
            <img
              className="col-6"
              src="/landingPage/weProvide/cheer.svg"
              alt=""
            />
            <Typography
              sx={{
                fontSize: 26,
                fontWeight: 800,
              }}
            >
              {active.name}
            </Typography>

            <Typography
              className="description"
              sx={{
                lineHeight: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {active.description}
            </Typography>
          </div>
          {leftElements}
          {rightElements}
        </div>
      </Box>

      {/* Small Screens */}
      <Box className="d-lg-none d-block">
        <Carousel
          className="col-12 mb-5 py-5 mx-auto"
          autoPlay={true}
          infinite={true}
          autoPlaySpeed={3000}
          arrows={false}
          responsive={responsive}
        >
          {leftData.concat(rightData).map((item, index) => {
            return (
              <div
                className="mobileCard p-3 mx-3 row align-items-center justify-content-center text-center"
                key="index"
              >
                <img className="col-8" src={item.image} alt="icon" />
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    padding: 0,
                    color: "#31436D",
                  }}
                >
                  {item.name}
                </Typography>
              </div>
            );
          })}
        </Carousel>
      </Box>
    </Wrapper>
  );
};

export default BestFeature;

const textBluePrimary = "#31436D";
const boxWidth = "53rem";
export const Wrapper = styled.div`
  background-color: #eef6ff;
  .heading {
    color: ${textBluePrimary};
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }
  .bold {
    font-weight: 800;
  }
  .desc {
    color: ${textBluePrimary};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 27px;
  }

  .section-heading {
    border-radius: 500px;
    border: 1px solid rgba(144, 156, 255, 0.3);
    background: #f5f6fe;
    color: #3347ed;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .imageCard {
    background-color: white;
    aspect-ratio: 16/9;
  }

  .outer-box {
    width: ${boxWidth};
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 3px dashed #d9d9d9;
    padding: 6rem;
    .inner-box {
      overflow: hidden;
      width: ${boxWidth};
      aspect-ratio: 1/1;
      background-color: #fff;
      border-radius: inherit;
      p {
        color: ${textBluePrimary};
      }
    }
    .inner-box i,
    .inner-box::before {
      content: "";
      float: left;
      height: 100%;
      width: 50%;
      shape-outside: radial-gradient(
        farthest-side at right,
        transparent calc(100% - 1rem),
        #fff 0
      );
    }
    .inner-box i {
      float: right;
      shape-outside: radial-gradient(
        farthest-side at left,
        transparent calc(100% - 1rem),
        #fff 0
      );
    }
    .element {
      display: flex;
      align-items: center;
      position: absolute;
      width: max-content;
      cursor: pointer;
      .icon-bubble {
        width: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
        background: #e4dcff;
        position: relative;
        border-radius: 50%;
      }
      .icon-bubble-left {
        margin-left: -1rem;
      }
      .icon-bubble-right {
        margin-right: -1rem;
      }
      .icon-bubble:before {
        content: "";
        position: absolute;
        right: 50%;
        top: 2.2rem;
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-right: 2.2rem solid #e4dcff;
        border-bottom: 1rem solid transparent;
        transform: rotate(66deg);
        border-radius: 3px;
      }
      .left {
        border-top-left-radius: 3rem;
        border-bottom-left-radius: 3rem;
      }
      .right {
        border-top-right-radius: 3rem;
        border-bottom-right-radius: 3rem;
      }
      p {
        margin: 0;
        color: ${textBluePrimary};
        padding: 1rem 1.5rem;
        background-color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        letter-spacing: 0em;
      }
    }

    .active-element {
      .icon-bubble,
      p {
        background-color: #2789fd;
        color: #fff;
      }
      .icon-bubble:before {
        border-right: 2.2rem solid #2789fd;
      }
    }
  }

  .mobileCard {
    background-color: #fff;
    aspect-ratio: 1/1;
    overflow: hidden;
  }

  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
      width: 100%;
    }
    .desc,
    .desc2 {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }
`;
