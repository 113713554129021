import React from "react";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styled from "styled-components";
export default function WeProvideCard({
  title = "Management",
  shortTitle,
  description,
  img,
}) {
  return (
    <Wrapper>
      <Card className="card">
        <CardContent className="h-100 row justify-content-center align-items-center">
          <div className="no-hover-content">
            <img
              src={img}
              alt="Icon"
              style={{ height: "130px", width: "130px", objectFit: "contain" }}
            />
            <Typography
              sx={{
                fontSize: {
                  md: 20,
                  xs: 14,
                },
                color: "#31436D",
                fontWeight: 700,
                opacity: 0.85,
                marginTop: 3,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "rgba(49, 67, 109, 0.75)",
                textAlign: "center",
                fontSize: {
                  md: 16,
                  xs: 12,
                },
                fontWeight: 500,
              }}
            >
              {shortTitle}
            </Typography>
          </div>
          <div className="hover-content">
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: "#fff",
              }}
            >
              {description}
            </Typography>
            {/* <Button className="viewMore px-3 py-2 mt-4">View More</Button> */}
          </div>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .card {
    cursor: pointer;
    border-radius: 2.1rem;
    border: 1px solid rgba(13, 33, 60, 0.2);
    background: #fff;
    box-shadow: 0px 0.8rem 1.75rem 0px rgba(19, 70, 137, 0.05);
    aspect-ratio: 1/1;
    .hover-content {
      display: none;
    }
  }
  .card:hover {
    transition: 0.5s;
    background-color: #2789fd;
    box-shadow: 0px 0px 50px 0px #0f52a280 inset;
    .no-hover-content {
      display: none;
    }
    .hover-content {
      display: block;
    }
  }
  .viewMore {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 0.8rem;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  img {
    min-height: 40%;
  }

  @media (max-width: 992px) {
    img {
      min-height: 15%;
    }
    .card:hover {
      .no-hover-content {
        display: block;
      }
      .hover-content {
        display: none;
      }
    }
  }
`;
