import { Outlet, useRoutes } from "react-router-dom";
import LandingPageFooter from "../pages/LandingPageFooter/LandingPageFooter";
import LandingPageHeader from "../pages/LandingPageHeader/LandingPageHeader";
import { ROUTING_NAMES } from "../constants/routingConstant";
import LandingPage from "../pages/LandingPage";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import RefundPolicy from "../pages/RefundPolicy";
import Services from "../pages/Services";
import Features from "../pages/Features";
import Pricing from "../pages/Pricing";
import Careers from "../pages/Careers";
import ContactUs from "../pages/ContactUs";
import GovermentCertified from "../pages/GovermentCertified";
import TermAndConditions from "../pages/TermAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Security from "../pages/Security";
import BlogPage from "../pages/Blog";

const RootRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <>
          <LandingPageHeader />
          <Outlet />
          <LandingPageFooter />
        </>
      ),
      children: [
        {
          path: ROUTING_NAMES.home,
          element: <LandingPage />,
        },
        {
          path: ROUTING_NAMES.services,
          element: <Services />,
        },
        {
          path: ROUTING_NAMES.features,
          element: <Features />,
        },
        {
          path: ROUTING_NAMES.pricing,
          element: <Pricing />,
        },
        {
          path: ROUTING_NAMES.aboutUs,
          element: <AboutUsPage />,
        },
        {
          path: ROUTING_NAMES.careers,
          element: <Careers />,
        },
        {
          path: ROUTING_NAMES.contact_us,
          element: <ContactUs />,
        },
        {
          path: ROUTING_NAMES.refundPolicy,
          element: <RefundPolicy />,
        },
        {
          path: ROUTING_NAMES.govermentCertified,
          element: <GovermentCertified />,
        },
        {
          path: ROUTING_NAMES.termAndConditions,
          element: <TermAndConditions />,
        },
        {
          path: ROUTING_NAMES.privacyPolicy,
          element: <PrivacyPolicy />,
        },
        {
          path: ROUTING_NAMES.security,
          element: <Security />,
        },
        {
          path: ROUTING_NAMES.blogs,
          element: <BlogPage />,
        },
      ],
    },
  ]);
};

export default RootRoutes;
