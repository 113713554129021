import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import WeProvideCard from "./sub-components/WeProvideCard";
const weProvideArray = [
  {
    id: 1,
    title: "Student Management",
    shortTitle: "Efficient Student Management",
    description:
      "Ekalsutra offers a comprehensive Student Management system tailored to meet the diverse needs of educational institutions. From enrollment to graduation, our platform simplifies student information management, academic records, and communication between educators, students, and parents. Experience seamless administration and foster student success with Ekalsutra. ",
    img: "/landingPage/weProvide/student-management.png",
  },
  {
    id: 2,
    title: "Fee Management",
    shortTitle: "Fee Management Made Easy",
    description:
      "Ensure financial transparency with Ekalsutra's Fee Management module. From generating invoices to tracking payments, our software helps you streamline financial processes, giving you peace of mind and allowing you to focus on providing quality education.",
    img: "/landingPage/weProvide/fee-management.png",
  },
  {
    id: 3,
    title: "Admission Management",
    shortTitle: "Admissions Made Effortless",
    description:
      "Streamline your admission process with Ekalsutra's user-friendly interface. From application submission to enrollment, our software simplifies the journey for both administrators and parents, ensuring a smooth start to the academic year. ",
    img: "/landingPage/weProvide/admission-approval.png",
  },
  {
    id: 4,
    title: "Ledger/Accounts",
    shortTitle: "Insightful Ledger and Accounts Reports",
    description:
      "Gain unparalleled financial visibility with Ekalsutra's Ledger Report feature. Track expenses, monitor revenue, and generate detailed financial reports effortlessly. Make informed decisions backed by accurate and up-to-date financial data. ",
    img: "/landingPage/weProvide/ledger-and-account.png",
  },
  // {
  //   id: 5,
  //   title: "School Management",
  //   shortTitle: "This module supervise the authorization via access",
  //   description:
  //     "Students and parents can stay informed about forthcoming events, functions, and vital information by visiting the school's notice board.",
  //   img: "/landingPage/weProvide/school-management.png",
  // },
  {
    id: 7,
    title: "Transport Management",
    shortTitle: "Effortless Transport Management",
    description:
      "Simplify the complexities of managing school transport. Ekalsutra's Transport Management module ensures efficient routing, monitors vehicle maintenance, and enhances safety protocols. Provide parents with real-time tracking and ensure a secure and reliable transportation system for students.",
    img: "/landingPage/weProvide/transportation.png",
  },
  {
    id: 8,
    title: "Academics Details",
    shortTitle: "Academic Excellence at Your Fingertips",
    description:
      "Empower your educators with a suite of tools designed to enhance academic management. Ekalsutra provides a centralized platform for lesson planning, grading, and progress tracking, fostering an environment conducive to educational success. ",
    img: "/landingPage/weProvide/academic-detail.png",
  },
  {
    id: 9,
    title: "Exam Control",
    shortTitle: "Streamlined Exam Control",
    description:
      "Take charge of examinations effortlessly with Ekalsutra's Exam Control feature. Our software facilitates exam scheduling, grading, and result management, ensuring a smooth and organized assessment process. From creating exam timetables to generating insightful performance reports, Ekalsutra supports educators in maintaining academic rigor and assessment integrity. ",
    img: "/landingPage/weProvide/exam-control.png",
  },
  {
    id: 10,
    title: "Reception Management",
    shortTitle: "Streamlined Reception Management",
    description:
      "Transform your school's front desk experience with Ekalsutra's Reception Management tool. From visitor registration to managing inquiries, streamline administrative tasks, and create a welcoming environment. Enhance security measures and ensure smooth operations at the forefront of your institution.",
    img: "/landingPage/weProvide/reception-management.png",
  },
  {
    id: 11,
    title: "Staff Management",
    shortTitle: "Efficient Staff Management",
    description:
      "Simplify HR tasks and enhance collaboration among your staff. Ekalsutra's Staff Management module covers everything from payroll to professional development, allowing your educators to focus on what they do best-teaching.",
    img: "/landingPage/weProvide/staff-management.png",
  },
];
const WeProvide = () => {
  return (
    <Wrapper>
      <Typography className="text-center">
        <span className="section-heading py-2 px-4">We Provide</span>
      </Typography>
      <Typography className="heading mt-5">
        Integrated <h1 className="bold heading mt-5">School Management Software</h1>
      </Typography>
      <Typography className="desc text-center mt-4">
        {/* <h3 className="bold">Ekalsutra</h3> software is a <h3 className="bold">customized school management software</h3> that
        offers end-to-end solutions for every phase of <h3 className="bold">school management</h3> like
        Admissions, Academic management, Staff management, Attendance, Fee
        Management. */}
        <h3 className="bold1">Ekalsutra</h3> is a <h3 className="bold1">customized school management software</h3> with comprehensive solutions for all aspects of <h3 className="bold1">school management</h3> including <h3 className="bold1">admissions management</h3>, <h3 className="bold1">academic management</h3>, <h3 className="bold1">staff management</h3> , <h3 className="bold1">attendance management</h3> and <h3 className="bold1">fee management</h3>.
      </Typography>

      <Box className="d-flex justify-content-lg-center flex-lg-wrap mt-3 overflow-auto cards">
        {weProvideArray?.map((item, i) => (
          <Box className="col-md-3 col-sm-6 col-8 my-4 mx-3" key={i}>
            <WeProvideCard
              title={item.title}
              shortTitle={item.shortTitle}
              description={item.description}
              img={item.img}
            />
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
};

export default WeProvide;

const textBluePrimary = "#31436D";
export const Wrapper = styled.div`
  .heading {
    color: ${textBluePrimary};
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .bold {
    font-weight: 800;
    display:inline;
    margin:inherit;
    padding:inherit;
  }
  .bold1 {
    font-weight: 800;
    display:inline;
    margin:inherit;
    padding:inherit;
    font-size:14px;
  }

  .desc {
    color: ${textBluePrimary};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 27px;
  }

  .section-heading {
    border-radius: 500px;
    border: 1px solid rgba(144, 156, 255, 0.3);
    background: #f5f6fe;
    color: #3347ed;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media (max-width: 968px) {
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
    }
    .desc {
      font-size: 1rem;
      line-height: 1.75rem;
    }
    .cards {
      width: 100vw;
    }
  }
`;
